import React, {useState, useEffect } from 'react';
import {useDraggable} from '@dnd-kit/core';
import {productVariantFromColor, ISelectedProduct} from "./selectionSlice";
import {Grid} from "@mui/material";
import {openProduct} from "../productDetails/productDetailsSlice";
import ColorTag from "../catalog/ColorTag";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import IconButton from "@mui/material/IconButton";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {IProduct} from "../../app/product";
import Box from "@mui/material/Box";
import GenderVersion from "../catalog/GenderVersion";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import {IProductColorImages, productUsageSelector} from "../dashboard/boutiqueSlice";
import {useIntl} from "react-intl";
import {useHelpTips} from "../../app/helpToolTip";
import {useNavigate} from "react-router-dom";

function SelectedProductCard (props: {
  selectedProduct: ISelectedProduct,
  catalogView: boolean,
  draggable: boolean,
  index: number
}) {

  const {selectedProduct, catalogView, draggable, index} = props;

  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  // Help tips of this component
  const componentName = catalogView ? 'selected-product' : draggable ? 'draft-product' : 'boutique-product'
  useHelpTips(componentName, intl, dispatch);

  // useAppSelector does not seem to be called every time in ProductDetails
  // const productBoutiquesAndCollections = useAppSelector((state) => productBoutiquesAndCollectionsSelector(state, selectedProduct.product.id));

  const productBoutiquesAndCollections = useSelector((state: RootState) =>
    productUsageSelector(state, {productId: selectedProduct.product.id, currentBoutiqueOnly: true}));

  const assigned = productBoutiquesAndCollections.length > 0;

  const [displayedProduct, setDisplayedProduct] = useState<IProduct>(selectedProduct.product);

  const {attributes, listeners, setNodeRef, transform} = useDraggable({
    id: 'product-' + selectedProduct.product.id,
    data: {
      type: 'product',
      product: selectedProduct.product,
      colors: selectedProduct.colors,
      otherVersions: selectedProduct.otherVersions ?
          selectedProduct.otherVersions.map(cp => ({product: cp.product, colors: cp.colors})) : []
    }
  })

  const style = transform ? {
    transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
  } : undefined;

  const displayedProductColors = () => {
    if (displayedProduct == selectedProduct.product || selectedProduct.otherVersions === undefined) {
      return selectedProduct.colors;
    } else {
      const displayedColors = selectedProduct.otherVersions
        .find(connectedSelProduct => connectedSelProduct.product == displayedProduct)?.colors;

      return displayedColors || selectedProduct.colors
    }
  }

  const firstVariant = productVariantFromColor(displayedProduct, displayedProductColors()[0]?.color);

  if (!firstVariant) {
    console.log("%cNo first variant in color '" + displayedProductColors()[0]?.color + "' for product " + displayedProduct.title + " with colors " + displayedProduct.variants.map(c => c.color).join(", "), 'color: red');
  }

  const imgScaleDown = displayedProduct.imgScaleDown

  if (selectedProduct.colors.length == 0) return null;

  const hoverGenderCallback = (product: IProduct, hovering: boolean) => {
    if (hovering) {
      setDisplayedProduct(product)
    } else {
      setDisplayedProduct(selectedProduct.product)
    }
  }

  function otherSelectedVersions() {
    if (!selectedProduct.otherVersions || selectedProduct.otherVersions.length === 0) return null;

    return(
      <Box className='otherSelectedVersions' >
        {selectedProduct.otherVersions.map(connectedSelProduct =>
          <GenderVersion key={connectedSelProduct.product.id}
                         product={connectedSelProduct.product}
                         catalogView={catalogView}
                         hoverCallback={hoverGenderCallback} />)}
      </Box>
    )
  }

  // console.log("Rendering SelectedProductCard " + selectedProduct.product.title)

  const toolTipTitle = selectedProduct.obsolete ? intl.formatMessage({id: "selection.obsolete"}) : displayedProduct.title;

  return (
    <Grid item xs={12} sm={6} className={`sel-product ${(assigned && !catalogView) ? 'assigned' : ''}`}
          key={selectedProduct.product.id} ref={setNodeRef} style={style}
          sx={{opacity: selectedProduct.obsolete ? 0.1 : 1 }}>
      {!catalogView && draggable && !selectedProduct.obsolete &&
        <IconButton id={`selection-drag-${index}`} className='grab' {...attributes} {...listeners}
                    sx={{position: 'absolute', left: '2px', top: '2px', color: 'orange', cursor:'grab'}}>
          <DragIndicatorIcon sx={{width: '0.7em', height: '0.7em'}} />
        </IconButton>
      }
      <div className="product-tbn" onClick={() => {
        dispatch(openProduct(displayedProduct))
        if (catalogView) {
          navigate(`/catalog/${displayedProduct.category}/${displayedProduct.slug}`, {replace: true});
          }
        }}>
        <BoudaToolTip title={toolTipTitle} arrow placement="left">
          <div className='first-img'>
            {firstVariant &&
              <img src={firstVariant.images[0]}
                   style={{width:`${100 - imgScaleDown}%`,marginTop:`${imgScaleDown/2}%`,marginBottom:`${imgScaleDown/2}%`}} />
            }
          </div>
        </BoudaToolTip>
        {otherSelectedVersions()}
        <div className='other-img'>
          {displayedProductColors().map((colorImages: IProductColorImages, idx: number) => {
            const variant = productVariantFromColor(displayedProduct, colorImages.color);
            return (variant &&
                <ColorTag key={`${idx}-${variant.hexColor1}-${variant.hexColor2}`}
                          color1={variant.hexColor1} color2={variant.hexColor2}
                          active={false} size='small' />
            )
          })}
        </div>
      </div>

    </Grid>
  )

}

export default SelectedProductCard;