import './website.scss';
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import Footer from "./Footer";
import {Helmet} from "react-helmet-async";
const TermsOfService = () => {

  const intl = useIntl();

  return (<>
    <Helmet>
      <meta name="title" content={`DAGOBA - ${intl.formatMessage({id: 'footer.cgs'})}`}/>
      <meta name="description" content={intl.formatMessage({id: 'home.description'})}/>
    </Helmet>
    <div className='website'>
      <Box className='section tos'>
        <h1>
          {intl.formatMessage({id: 'footer.cgs'})}
        </h1>
        <h2>
          Préambule
        </h2>
        <p>
          DAGOBA propose la création de e-boutiques gratuitement tout en assurant leur gestion.
        </p>
        <p>
          En naviguant sur le site Internet disponible à l’adresse www.dagoba.app, chaque Utilisateur s’engage à
          lire et à accepter pleinement et entièrement les présentes Conditions Générales de Service. Ainsi, à la date de la
          première connexion, les Conditions Générales de Service sont réputées lues, comprises et acceptées sans réserve par
          l’Utilisateur.
        </p>
        <p>
          DAGOBA se réserve le droit de compléter, de modifier et de faire évoluer les présentes Conditions
          générales à tout moment. En tout état de cause, toute modification significative sera notifiée à l’Utilisateur par
          l’introduction d’un pop-up sur la Plateforme ou par mail. Elles sont par ailleurs librement accessibles et
          consultables par toute personne naviguant ou utilisant le Site.
        </p>
        <h2>
          Article 1 : Définitions
        </h2>
        <p>
          Dans les présentes Conditions générales, les termes commençant par une majuscule reçoivent la définition
          figurant ci-après :
        </p>
        <p>
          «
          <strong>
            Boutique Partenaire
          </strong>
          » désigne la e-boutique de chaque Partenaire.
        </p>
        <p>
          «
          <strong>
            Cagnotte
          </strong>
          » désigne le portefeuille virtuel du Partenaire correspondant au montant des redevances qui lui sont
          versées
          par DAGOBA suite aux commandes passées sur sa Boutique Partenaire.
        </p>
        <p>
          «
          <strong>
            Client
          </strong>
          » désigne toute personne qui passe une commande sur une Boutique Partenaire.
        </p>
        <p>
          «
          <strong>
            Communications
          </strong>
          » désigne les flyers, visuels, campagnes de mailing, e-coupons et autres que le Partenaire peut utiliser à
          partir de son Tableau de bord afin de dynamiser les ventes.
        </p>
        <p>
          «
          <strong>
            DAGOBA
          </strong>
          » désigne la société éditrice de la Plateforme disponible à l’adresse URL « www.dagoba.app ».
        </p>
        <p>
          «
          <strong>
            Conditions générales
          </strong>
          » désigne le présent contrat.
        </p>
        <p>
          «
          <strong>
            Contenu Partenaire
          </strong>
          » désigne tout contenu relatif au Partenaire tels que dénomination, marque, logo, dessin etc.
        </p>
        <p>
          «
          <strong>
            Identifiants
          </strong>
          » désigne l’identifiant et le mot de passe propres à chaque Partenaire pour accéder à son Tableau de bord.
        </p>
        <p>
          «
          <strong>
            Partenaire
          </strong>
          » désigne tout Utilisateur qui a souscrit aux Services.
        </p>
        <p>
          «
          <strong>
            Plateforme
          </strong>
          » désigne les applications, sites web et mobiles accessibles depuis l'adresse <a href="https://www.dagoba.app/">
               https://www.dagoba.app
            </a>
        </p>
        <p>
          «
          <strong>
            Produits
          </strong>
          » désigne les articles proposés à la vente sur les Boutiques Partenaires.
        </p>
        <p>
          «
          <strong>
            Règlementation relative aux données à caractère personnel
          </strong>
          » désigne l’ensemble des dispositions relatives aux données à caractère personnel et plus spécifiquement à
          la Loi Informatique et Libertés du 6 Janvier 1978 et au Règlement général sur la protection des données 2016/679.
        </p>
        <p>
          «
          <strong>
            Services
          </strong>
          » désigne les services de création et de gestion de e-boutique proposés par DAGOBA.
        </p>
        <p>
          «
          <strong>
            Tableau de bord
          </strong>
          » désigne l’espace personnel auquel le Partenaire a accès après avoir souscrit aux Services.
        </p>
        <p>
          «
          <strong>
            Utilisateur
          </strong>
          <strong>
          </strong>
          » désigne indifféremment toute personne qui se rend sur la Plateforme.
        </p>
        <h2>
          Article 2 : Objet de la Plateforme
        </h2>
        <p>
          La Plateforme a pour objet de permettre la création et la gestion d’une Boutique Partenaire et ce, gratuitement.
        </p>
        <p>
          DAGOBA propose en effet de créer pour le compte du Partenaire une Boutique Partenaire et d’assurer la
          gestion logistique des commandes passées sur ladite Boutique Partenaire, de la passation de la commande à sa
          livraison.
        </p>
        <h2>
          Article 3 : Création d’une Boutique Partenaire
        </h2>
        <h3>
          Article 3.1 : Conditions requises pour toute création d’une Boutique Partenaire
        </h3>
        <p>
          Les Services fournis sont exclusivement destinés aux personnes ayant atteint l’âge de la majorité dans le
          territoire de souscription et d’utilisation des Services.
        </p>
        <p>
          DAGOBA se réserve le droit de refuser la création d'une boutique, ou de fermer une boutique existante, sans avoir à fournir d'explication sur
          la raison du refus ou de la fermeture.
        </p>
        <h3>
          Article 3.2 : Création d’une Boutique Partenaire
        </h3>
        <p>
          Afin de créer sa Boutique Partenaire, et ainsi souscrire aux Services, l’Utilisateur doit demander la création
          d'une boutique en remplissant le formulaire.
        </p>
        <p>
          Une fois la boutique créée, le Partenaire aura accès aux Services et à son Tableau de bord à travers les
          Identifiants qu’il aura choisis lui-même (email fourni par le Partenaire et mot de passe choisi par le
          Partenaire).
        </p>
        <p>
          Le Partenaire s’engage à garder ses Identifiants confidentiels et à ne pas les céder ni les concéder à des
          tiers.
        </p>
        <p>
          DAGOBA s’engage pour sa part à prendre les mesures nécessaires afin d’empêcher la divulgation des
          Identifiants du Partenaire.
        </p>
        <p>
          Si un tiers accède au Tableau de bord du Partenaire grâce aux Identifiants, celui-ci pourra réaliser
          toutes les modifications que le Partenaire peut effectuer et notamment gérer sa Cagnotte.
        </p>
        <p>
          Le Partenaire supporte la pleine responsabilité des actions réalisées sur son Tableau de bord.
        </p>
        <p>
          Le Partenaire, lors de la souscription des Services auprès de DAGOBA, s’engage à renseigner des
          informations exactes, complètes et à jour.
        </p>
        <h2>
          Article 4 : Fonctionnement de la Boutique Partenaire
        </h2>
        <h3>
          4.1 Gestion de la Boutique Partenaire
        </h3>
        <p>
          La Boutique Partenaire est entièrement créée par le Partenaire notamment en ce qui concerne le choix des
          Produits qui sont proposés à la vente et le choix du nom de domaine à utiliser
          constituant l’adresse URL à partir de laquelle la Boutique Partenaire sera disponible.
        </p>
        <p>
          S’agissant des Produits, le Partenaire peut choisir les produits à partir des catalogues présentés sur la
          Plateforme.
        </p>
        <p>
          S’agissant du nom de domaine, DAGOBA n’enregistre jamais de nom de domaine pour le compte du Partenaire.
          Le Partenaire peut choisir que sa Boutique Partenaire soit accessible à une adresse URL composée d’un
          sous-domaine DAGOBA à déterminer en concertation avec DAGOBA ou que sa Boutique Partenaire soit
          implémentée sous un nom de domaine que le Partenaire a d’ores et déjà enregistré.
        </p>
        <p>
          La Boutique Partenaire est entièrement gérée par DAGOBA qui s’occupe de tout, de l’approvisionnement des
          Produits à la livraison de la commande au Client.
        </p>
        <p>
          Le Client passe ainsi sa commande en ligne sur la Boutique Partenaire et, le cas échéant personnalise les
          Produits qu’il souhaite acheter.
        </p>
        <p>
          DAGOBA prend ensuite en charge la réception du paiement de la commande par le Client, la fabrication des
          Produits commandés, leur personnalisation le cas échéant, et l’envoi de la commande au Client.
        </p>
        <p>
          De la même manière, DAGOBA prend en charge le service après-vente relatif aux commandes passées sur la
          Boutique Partenaire.
        </p>
        <h3>
          4.2 Fonctionnalités du Tableau de bord
        </h3>
        <p>
          Le Partenaire a accès à un Tableau de bord via ses Identifiants.
        </p>
        <p>
          Le Tableau de bord permet au Partenaire :
        </p>
        <ul>
          <li>
            <p>
              de suivre les commandes effectuées sur la Boutique Partenaire ;
            </p>
          </li>
          <li>
            <p>
              de suivre les Produits vendus sur la Boutique Partenaire ;
            </p>
          </li>
          <li>
            <p>
              de suivre les Clients inscrits sur la Boutique Partenaire ;
            </p>
          </li>
          <li>
            <p>
              d’accéder au chiffre d’affaires généré sur la Boutique Partenaire ;
            </p>
          </li>
          <li>
            <p>
              de gérer sa Cagnotte (réutilisation, émission de coupons d’achat ou retrait des sommes) ; et
            </p>
          </li>
          <li>
            <p>
              de bénéficier des outils relatifs aux Communications pour dynamiser les ventes sur la Boutique
              Partenaire.
            </p>
          </li>
        </ul>
        <h3>
          4.3 Support et assistance
        </h3>
        <p>
          Les prestations de support et d’assistance sont réalisées par DAGOBA par l'intermédiaire du formulaire
          « Assistance » présent sur la Plateforme.
        </p>
        <h2>
          Article 5 : Durée
        </h2>
        <p>
          Le Partenaire souscrit aux Services sans engagement de durée.
        </p>
        <p>
          A tout moment, le Partenaire peut décider de résilier les Services, c’est-à-dire désactiver sa boutique.
          Pour ce faire, le Partenaire peut contacter le service de support et d’assistance et selon les modalités de
          résiliation prévues à l’Article 12 ci-après.
        </p>
        <h2>
          Article 6 : Conditions financières
        </h2>
        <p>
          En contrepartie des Services rendus au Partenaire, DAGOBA se rémunère via les commandes passées sur la
          Boutique Partenaire.
        </p>
        <p>
          En contrepartie de la cession de droits de propriété intellectuelle telle que prévue à l’Article 9.2
          ci-après, le Partenaire percevra s’il le demande une redevance sur les Produits vendus sur la Boutique Partenaire en
          fonction des marges qu'il aura mises en place sur chaque produit dans le Tableau de Bord de la Boutique.
        </p>
        <p>
          Cette redevance sera versée après toute vente, automatiquement sur sa Cagnotte et ce, dans un délai de
          quinze (15) jours à compter du paiement de la commande.
        </p>
        <p>
          Si la commande est ultérieurement annulée et remboursée au Client, entièrement ou partiellement, le
          montant crédité sur la Cagnotte du Partenaire pour cette commande sera consécutivement déduit.
        </p>
        <h2>
          Article 7 : Obligations du Partenaire
        </h2>
        <h3>
          7.1 Obligations générales
        </h3>
        <p>
          Le Partenaire s’engage à utiliser la Plateforme conformément aux présentes Conditions générales et plus
          généralement conformément à l’ensemble des dispositions légales en vigueur.
        </p>
        <p>
          Le Partenaire s’engage également à ne pas porter atteinte à la sécurité et à l’intégrité de la Plateforme.
        </p>
        <h3>
          7.2 Obligations relatives aux Communications
        </h3>
        <p>
          Le Partenaire peut être amené, à travers les outils mis à sa disposition par DAGOBA dans son Tableau de
          bord,
          à diffuser des Communications.
        </p>
        <p>
          Le contenu des Communications peut être fourni par DAGOBA (et dans ce cas le Partenaire possède la faculté
          de
          le modifier), et/ou est créé de façon autonome par le Partenaire.
        </p>
        <p>
          A cet égard, il est précisé que DAGOBA ne peut en aucun cas être considérée comme responsable desdites
          Communications dès lors que leur contenu aura été modifié par le Partenaire. Le Partenaire reste ainsi
          seul responsable du contenu des Communications qu’il diffuse.
        </p>
        <p>
          Le Partenaire s’engage ainsi à respecter la législation et la réglementation en vigueur, notamment en
          veillant
          à ne pas mettre à disposition, diffuser ou donner accès à des informations, données, documents, services
          ou
          autres éléments illicites, contraires aux dispositions légales et/ou règlementaires ou étant susceptibles
          de
          porter atteinte aux droits d’autrui ou à l’ordre public.
        </p>
        <p>
          Le Partenaire s’engage notamment à ne pas diffuser, publier, introduire des informations ou documents
          illicites et notamment contraires à l’ordre public, comportant des éléments ou contenus à caractère
          pornographique, diffamatoire ou injurieux au sens de la loi, portant atteinte à l’intimité de la vie
          privée, à
          la protection des données à caractère personnel, constituant une contrefaçon ou une atteinte à un droit de
          propriété d’un tiers y compris des droits de propriété intellectuelle, constituant un piratage
          informatique,
          incitant à la violence ou à la haine raciale, incitant à commettre une contravention, un délit, un crime
          ou un
          acte de terrorisme ou faisant l’apologie de crimes de guerre ou de crimes contre l’humanité, ou incitant
          au
          suicide, ou étant en infraction à des droits de tiers.
        </p>
        <p>
          D’une façon générale, le Partenaire garantit DAGOBA contre toute contestation, recours ou action de tiers
          à ce
          titre.
        </p>
        <h3>
          7.3 Obligations relatives à la promotion de la Boutique Partenaire
        </h3>
        <p>
          DAGOBA assure la création, l'hébergement et la gestion opérationnelle de la Boutique Partenaire. En échange de cet
          investissement et de ces frais récurrents, le Partenaire doit assurer la promotion de la Boutique
          Partenaire auprès du public cible de la Boutique Partenaire.
        </p>
        <p>
          Il s'engage notamment à installer et conserver un lien internet permanent et bien visible (typiquement
          dans l'entête ou le pied de page) de son site web officiel (s'il existe) et de ses pages Facebook et Instagram
          (si elles existent), ou de tout autre site ou réseau social utilisé principalement par le Partenaire
          pour communiquer avec le public cible de la Boutique Partenaire.
        </p>
        <h2>
          Article 8 : Obligations de DAGOBA
        </h2>
        <h3>
          8.1 Continuité des Services
        </h3>
        <p>
          DAGOBA s’engage à tout mettre en œuvre pour assurer la continuité des Services.
        </p>
        <p>
          A cet égard, DAGOBA s’engage à :
        </p>
        <ul>
          <li>
            <p>
              apporter à la fourniture des Services, directement ou indirectement, tout le soin d’un professionnel
              diligent dans le cadre d’une obligation de moyens ;
            </p>
          </li>
          <li>
            <p>
              se comporter loyalement envers le Partenaire et à lui fournir les Services conformément aux présentes
              Conditions générales ; et
            </p>
          </li>
          <li>
            <p>
              affecter à la fourniture des Services les moyens humains et matériels nécessaires.
            </p>
          </li>
        </ul>
        <p>
          DAGOBA ne pourra être tenue responsable des erreurs et dommages résultant d’une mauvaise utilisation des
          Services par le Partenaire.
        </p>
        <p>
          DAGOBA pourra modifier ou faire évoluer la Plateforme et/ou les Services. En cas d’évolution ou de
          modification substantielle, DAGOBA en informera le Partenaire avec un préavis raisonnable avant que
          celle-ci
          ne prenne effet.
        </p>
        <p>
          DAGOBA peut décider unilatéralement de fermer la Boutique Partenaire s'il constate une utilisation non
          conforme aux présentes Conditions générales, un non respect des obligations du Partenaire ou une
          utilisation
          déloyable de la Boutique Partenaire.
        </p>
        <p>
        </p>
        <h3>
          8.2 Prestations d’assistance
        </h3>
        <p>
          DAGOBA s’engage à fournir aux Partenaires les prestations de support et d’assistance selon les modalités
          décrites à l’Article 4.3 ci-avant.
        </p>
        <h2>
          Article 9 : Propriété intellectuelle
        </h2>
        <h3>
          9.1 La Plateforme
        </h3>
        <p>
          L’Utilisateur reconnait que DAGOBA est l’unique titulaire de l’ensemble des droits de propriété
          intellectuelle
          afférents à la Plateforme et à la Boutique Partenaire (et notamment tout contenu de cette dernière qui
          ferait
          l'objet d'une protection par le Code de la propriété intellectuelle et plus particulièrement par le droit
          des
          marques et le droit d’auteur).
        </p>
        <p>
          L’Utilisateur s’interdit à cet égard d’extraire, de représenter, de reproduire ou plus généralement de
          porter
          atteinte à tout ou partie de la Plateforme et/ou d’une Boutique Partenaire.
        </p>
        <h3>
          9.2 Le Contenu Partenaire
        </h3>
        <p>
          DAGOBA ne pourra revendiquer la titularité du Contenu Partenaire.
        </p>
        <p>
          Le Partenaire garantit être titulaire des droits de propriété intellectuelle afférents au Contenu
          Partenaire
          ou des autorisations nécessaires à son utilisation.
        </p>
        <p>
          A cet égard, dans le cadre de la création et de la gestion de la Boutique Partenaire, le Partenaire
          concède à
          DAGOBA, à titre non exclusif, le droit de reproduire et de représenter le Contenu Partenaire sur tous
          supports
          et ce, pour le monde entier et pour la durée de sa souscription aux Services.
        </p>
        <p>
          Le Partenaire garantit à DAGOBA la jouissance paisible et libre de toutes servitudes des droits concédés
          contre tous troubles, revendications, réclamations et évictions quelconques, notamment contre toute action
          d’un tiers en contrefaçon ou en concurrence déloyale ou toute autre violation des droits des tiers.
        </p>
        <p>
          En cas de manquement par le Partenaire aux stipulations de la présente clause, DAGOBA pourra procéder au
          retrait du contenu litigieux, à la fermeture de sa Boutique Partenaire ainsi qu’à la résiliation des
          présentes
          Conditions générales dans les conditions décrites ci-après à l’Article 12.
        </p>
        <h2>
          Article 10 : Données à caractère personnel
        </h2>
        <h3>
          10.1 Données collectées
        </h3>
        <p>
          Dans le cadre des présentes Conditions générales DAGOBA est susceptible de collecter les données suivantes
          :
        </p>
        <ul>
          <li>
            <p>
              données d’identification : civilité, prénom, nom, fonction, langue et pays, adresse e-mail, adresse
              postale et numéro de téléphone ;
            </p>
          </li>
          <li>
            <p>
              données de paiement du Partenaire : coordonnées bancaires du Partenaire pour les retraits par virement
              de
              la Cagnotte (DAGOBA précise que toutes les transactions de paiement des Clients sur la Boutique
              Partenaire
              et du Partenaire dans le Tableau de Bord sont cryptées et stockées par la banque réceptrice, et non
              par
              DAGOBA),
            </p>
          </li>
          <li>
            <p>
              données de connexion (telles que l’identifiant et le mot de passe du Partenaire) et de navigation ;
            </p>
          </li>
          <li>
            <p>
              données commerciales telles que les préférences de l’Utilisateur.
            </p>
          </li>
        </ul>
        <p>
          Certaines données doivent être obligatoirement renseignées par l’Utilisateur pour accéder à certaines
          fonctionnalités de la Plateforme puisqu’il s’agit de données dont DAGOBA a besoin pour permettre à
          l’Utilisateur d’accéder à ces fonctionnalités.
        </p>
        <p>
          DAGOBA ne traite aucune donnée à caractère personnel qui pourrait être qualifiée de « sensible »
          (information
          concernant l’origine raciale ou ethnique, les opinions politiques, philosophiques ou religieuses,
          l’appartenance syndicale, la santé ou la vie sexuelle) au sens de la Règlementation relative aux données à
          caractère personnel.
        </p>
        <p>
          DAGOBA s’engage à ne pas transférer de données à caractère personnel à des tiers sauf exceptions listées
          ci-après à l’Article 10.5.
        </p>
        <h3>
          10.2 Cookies
        </h3>
        <p>
          DAGOBA utilise des cookies et d’autres technologies similaires pour faciliter la navigation de
          l’Utilisateur
          sur la Plateforme en l’adaptant à l’équipement ou au moteur de recherche de l’Utilisateur.
        </p>
        <p>
          Les cookies permettent de collecter plusieurs types de données :
        </p>
        <ul>
          <li>
            <p>
              données d’utilisation qui permettent à DAGOBA d’analyser la façon dont les Utilisateurs exploitent la
              Plateforme ;
            </p>
          </li>
          <li>
            <p>
              données techniques : adresse IP, moteur de recherche, localisation et fuseau horaire, extension de
              navigateur, système d’exploitation, appareil utilisé pour la connexion ; et
            </p>
          </li>
          <li>
            <p>
              informations sur la visite des Utilisateurs sur la Plateforme, incluant les adresses URL et le
              parcours
              des Utilisateurs sur celle-ci, le temps de réponse des pages, les erreurs de téléchargement.
            </p>
          </li>
        </ul>
        <h3>
          10.3 Finalités des traitements
        </h3>
        <p>
          Les données à caractère personnel listées aux Articles 10.1 et 10.2 sont susceptibles d’être traitées pour
          les
          finalités suivantes :
        </p>
        <ul>
          <li>
            <p>
              Gestion de la souscription aux Services : le traitement des données est notamment nécessaire pour le
              bon
              fonctionnement des Services, la création et la gestion de la Boutique Partenaire, le traitement des
              paiements y afférents, l’accès au service d’assistance et de support ;
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Amélioration des Services proposés par DAGOBA : DAGOBA peut traiter les données de navigation et de
              souscription des Utilisateurs à des fins d’analyse et de statistique dans l’objectif d’en améliorer
              l’ergonomie et la qualité ; et
            </p>
          </li>
          <li>
            <p>
              Marketing : dans le cadre de l’abonnement à la newsletter DAGOBA, cette dernière traite les données
              des
              Utilisateurs afin de leur envoyer des informations ciblées par email.
            </p>
          </li>
        </ul>
        <p>
          Les traitements ayant pour finalité le marketing nécessitent le consentement de l’Utilisateur,
          consentement
          révocable à tout moment.
        </p>
        <h3>
          10.4 Fondements légaux
        </h3>
        <p>
          Les traitements des données à caractère personnel des Utilisateurs peuvent avoir plusieurs fondements
          légaux
          selon les hypothèses.
        </p>
        <p>
          Lorsqu’un Utilisateur souscrit aux Services à partir de la Plateforme, DAGOBA collecte et traite ses
          données à
          caractère personnel afin de fournir les Services.
        </p>
        <p>
          DAGOBA se base également sur d’autres fondements légaux tels que les intérêts légitimes de l’Utilisateur.
        </p>
        <p>
          DAGOBA apporte un soin particulier à respecter l’importance accordée au consentement dans la
          Règlementation
          relative à la protection des données à caractère personnel.
        </p>
        <h3>
          10.5 Destinataires des traitements
        </h3>
        <p>
          Dans le cadre de l’utilisation de la Plateforme, DAGOBA peut partager des données à caractère personnel
          avec
          certains tiers listés ci-après :
        </p>
        <ul>
          <li>
            <p>
              des établissements financiers afin de procéder à des paiements ;
            </p>
          </li>
          <li>
            <p>
              des entités de détection et de prévention des fraudes afin de vérifier le crédit et l’identité du
              Partenaire ;
            </p>
          </li>
          <li>
            <p>
              des prestataires informatiques pour la sécurisation des données bancaires ; et
            </p>
          </li>
          <li>
            <p>
              des prestataires de services liés au marketing et à la publicité afin d’optimiser l’expérience de
              l’Utilisateur sur la Plateforme.
            </p>
          </li>
        </ul>
        <p>
          DAGOBA requiert des tiers destinataires des données à caractère personnel des Utilisateurs de s’engager à
          respecter la Règlementation relative à la protection des données à caractère personnel.
        </p>
        <p>
          Les tiers listés ci-avant peuvent seulement utiliser les données à caractère personnel transmises selon
          les
          instructions données par DAGOBA et non pour leur propre usage.
        </p>
        <h3>
          10.6 Durée de conservation des données
        </h3>
        <p>
          DAGOBA conserve les données du Partenaire durant toute la durée de souscription aux Services mais
          également
          pendant une durée de dix (10) ans dans l’hypothèse où des versements ont eu lieu suivant le dernier
          versement
          sur la Cagnotte du Partenaire
          et ce, afin de répondre à toutes questions, plaintes ou pour maintenir toutes les données nécessaires afin
          de
          répondre à des exigences légales, de comptabilité ou d’analyse (article L.123-22 du Code du commerce).
        </p>
        <p>
          DAGOBA peut aussi conserver vos données pour de la recherche ou des analyses statistiques ; celles-ci
          seront
          alors anonymisées.
        </p>
        <p>
          Dans le cadre d’un abonnement à la newsletter, DAGOBA ne recontactera plus l’Utilisateur si ce dernier n’a
          pas
          ouvert les newsletters depuis plus de douze (12) mois.
        </p>
        <p>
          DAGOBA peut également conserver les données de l’Utilisateur pour de la recherche ou des analyses
          statistiques. Celles-ci étant alors anonymisées, aucune durée de conservation n’est imposée par la
          Règlementation dans la mesure où il n’est plus possible de « ré-identifier » l’Utilisateur par la suite.
        </p>
        <h3>
          10.7 Droits des personnes concernées
        </h3>
        <p>
          Conformément à la Règlementation, chaque Utilisateur bénéficie sur ses données personnelles des droits
          suivants :
        </p>
        <ul>
          <li>
            <p>
              Droit d’accès aux données : ce droit permet à tout Utilisateur de recevoir une copie des données
              personnelles que DAGOBA détient à son sujet ;
            </p>
          </li>
          <li>
            <p>
              Droit à la rectification : ce droit laisse la possibilité à l’Utilisateur de demander la correction de
              données personnelles inexactes ou incomplètes le concernant ;
            </p>
          </li>
          <li>
            <p>
              Droit à la suppression : ce droit donne la possibilité à tout Utilisateur de demander la suppression
              de
              ses données personnelles si l’un des motifs légaux est rempli :
            </p>
          </li>
        </ul>
        <ul>
          <ul>
            <ul>
              <li>
                <p>
                  les données personnelles ne sont plus nécessaires au regard des finalités du traitement ;
                </p>
              </li>
              <li>
                <p>
                  l’Utilisateur a retiré son consentement sur lequel le traitement est fondé ;
                </p>
              </li>
              <li>
                <p>
                  l’Utilisateur s’est opposé au traitement par le biais de son droit d’opposition ;
                </p>
              </li>
              <li>
                <p>
                  le traitement dont ses données personnelles font l’objet est illicite ; ou
                </p>
              </li>
              <li>
                <p>
                  ses données personnelles doivent être effacées en vertu d’une obligation légale.
                </p>
              </li>
            </ul>
          </ul>
          <li>
            <p>
              Droit à la limitation du traitement ; cette limitation du traitement peut être réalisée lorsque :
            </p>
          </li>
        </ul>
        <ul>
          <ul>
            <li>
              <p>
                l’Utilisateur conteste l’exactitude de ses données personnelles ;
              </p>
            </li>
            <li>
              <p>
                l’Utilisateur ne s’oppose pas à l’effacement de ses données personnelles dans le cadre d’un
                traitement
                illicite mais exige qu’il soit limité ;
              </p>
            </li>
            <li>
              <p>
                ses données personnelles ne sont plus utilisées par les services de DAGOBA mais que leur
                conservation
                est uniquement nécessaire pour la constatation, l’exercice ou la défense d’un droit en justice ; ou
              </p>
            </li>
            <li>
              <p>
                l’Utilisateur s’oppose à ce que DAGOBA utilise ses données personnelles par le biais de son droit
                d’opposition.
              </p>
            </li>
          </ul>
        </ul>
        <ul>
          <li>
            <p>
              Droit à la portabilité : l’Utilisateur peut contacter DAGOBA pour que DAGOBA fournisse, à lui ou
              directement à un autre responsable de traitement, ses données personnelles dans un format structuré,
              couramment utilisé et lisible par machine ;
            </p>
          </li>
          <li>
            <p>
              Droit d’opposition : l’Utilisateur peut contacter DAGOBA lorsque
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              le traitement est réalisé pour répondre à une mission d’intérêt public ou aux fins des intérêts
              légitimes
              de DAGOBA ; l’Utilisateur peut alors s’y opposer uniquement pour des raisons tenant à sa situation ;
              ou
            </p>
          </li>
          <li>
            <p>
              le traitement est réalisé dans un but de prospection commerciale ; l’Utilisateur peut s’y opposer à
              tout
              moment et sans qu’aucune explication ne soit donnée.
            </p>
          </li>
        </ul>
        <ul>
          <li>
            <p>
              Droit de déterminer le sort de ses données personnelles après le décès de l’Utilisateur
              (l’e-Testament) :
              l’une des spécificités de la règlementation française est qu’elle permet à l’Utilisateur d’adresser
              ses
              directives concernant la manière dont DAGOBA traitera ses données personnelles après son décès. A ce
              titre
              l’Utilisateur peut choisir qu’elles soient supprimées ou bien transmises à l’un de ses proches.
            </p>
          </li>
        </ul>
        <p>
          Indépendamment de la finalité ou de la base légale en vertu de laquelle DAGOBA traite les données
          personnelles de l’Utilisateur, ce dernier peut donc, à tout moment et sans aucun coût, adresser à DAGOBA un courrier
          électronique pour bénéficier de ses droits à l’adresse suivante : contact@dagoba.app.
        </p>
        <p>
          Ou lui faire parvenir un courrier à l’adresse suivante : 10 rue de Penthièvre, 75008 Paris
          .
        </p>
        <p>
          DAGOBA n’est pas toujours en mesure de répondre favorablement à cette demande pour des raisons légales
          qu’elle portera à la connaissance de l’Utilisateur, le cas échéant, après avoir reçu sa demande.
        </p>
        <p>
          Le Partenaire peut également modifier ses données personnelles depuis son Tableau de bord en s’identifiant
          à l’aide de son adresse électronique et de son mot de passe.
        </p>
        <p>
          En tout état de cause, l’Utilisateur bénéficie du droit d’introduire une réclamation auprès de la CNIL.
        </p>
        <h3>
          10.8 Sécurité
        </h3>
        <p>
          DAGOBA dispose de mesures de sécurité appropriées afin de prévenir toute perte, toute utilisation ou tout
          accès non autorisé, toute modification ou divulgation des données à caractère personnel.
        </p>
        <p>
          Toutes les données à caractère personnel collectées et traitées par DAGOBA sont sauvegardées sur des
          serveurs
          sécurisés.
        </p>
        <p>
          DAGOBA recommande à tout Utilisateur de se connecter uniquement sur des réseaux sécurisés et d’éviter de
          se
          connecter à partir des réseaux wifi publiques.
        </p>
        <h3>
          10.9 Engagements du Partenaire
        </h3>
        <p>
          Le Partenaire s’engage pour sa part à respecter la Règlementation relative aux données à caractère
          personnel
          pour toute collecte et tout traitement de ce type de données qu’il effectuerait par le biais de la
          Boutique
          Partenaire et plus particulièrement par le biais du Tableau de bord.
        </p>
        <p>
          Le Partenaire garantit DAGOBA contre tout trouble, recours ou action à ce titre.
        </p>
        <h2>
          Article 11 : Responsabilité
        </h2>
        <p>
          Même en cas de manquement, de faute ou de diligences insuffisantes de DAGOBA, et quel que soit le
          fondement
          sur lequel sa responsabilité est recherchée, DAGOBA n'est responsable envers le Partenaire dans le cadre
          des
          présentes Conditions générales que des seuls dommages directs, à l'exclusion des dommages indirects tels
          que
          perte d’une chance, perte d’image, préjudice commercial, perte de profit ou d’économie escomptée, perte de
          clientèle, perte de bénéfice ou de chiffre d’affaires, que ces dommages aient eu ou non un caractère
          prévisible dans leur principe ou leur ampleur.
        </p>
        <p>
          La responsabilité de DAGOBA ne pourra être valablement engagée que si le Partenaire apporte la preuve
          d’une
          faute de DAGOBA dans l’exécution de ses obligations, étant entendu que les parties reconnaissent que la
          faute
          de DAGOBA ne peut être relative qu’à la partie des infrastructures qu’elle gère et sur lesquelles elle
          exerce
          un pouvoir de contrôle.
        </p>
        <p>
          DAGOBA propose des Services « tels quels » et « tels que disponibles » ; elle n’est par conséquent pas
          tenue
          de s’assurer de l’adéquation des Services aux besoins du Partenaire.
        </p>
        <p>
          DAGOBA ne garantit pas le parfait fonctionnement de la Plateforme ni des Boutiques Partenaires mais mettra
          en
          œuvre ses meilleurs efforts pour assurer la continuité des Services.
        </p>
        <p>
          DAGOBA ne garantit pas non plus l’absence de toute erreur ou de tout défaut lors de l’utilisation de la
          Plateforme. En cas de notification d’une erreur ou d’un défaut, DAGOBA s’engage, dans la mesure du
          possible, à
          y remédier dans un délai raisonnable.
        </p>
        <p>
          En outre, DAGOBA se réserve le droit, par un acte volontaire, de rendre la Plateforme et/ou les Boutiques
          Partenaires indisponible(s) afin de réaliser toute opération de mise à jour, d'amélioration ou de
          maintenance.
        </p>
        <h2>
          Article 12 : Résiliation de la souscription aux Services
        </h2>
        <p>
          Tout Partenaire peut résilier sa souscription aux Services à tout moment en contactant DAGOBA soit par
          mail,
          soit par téléphone.
        </p>
        <p>
          Dans cette hypothèse, la somme restante sur la Cagnotte du Partenaire le cas échéant sera reversée au
          Partenaire dans un délai de vingt et un (21) jours suivant la notification de la résiliation, sous réserve
          des
          sommes correspondant à des commandes non encore livrées ou encore susceptibles d’être annulées et
          remboursées
          à des Clients.
        </p>
        <p>
          DAGOBA peut réfuser de fournir les Services ou résilier la souscription aux Services et suspendre la
          Boutique
          Partenaire à tout moment avec un préavis de 48 heures. La résiliation de la souscription aux Services par
          DAGOBA ne donnera droit à aucune compensation envers le Partenaire.
        </p>
        <p>
          Toute violation des présentes Conditions générales pourra entraîner la résiliation unilatérale sans
          préavis de
          la souscription aux Services par DAGOBA ainsi que la suspension immédiate de la Boutique Partenaire
          concernée.
        </p>
        <h2>
          Article 13 : Restitution des données
        </h2>
        <p>
          A compter de la résiliation de la souscription aux Services, DAGOBA révoquera les accès du Partenaire à
          son
          Tableau de bord et s’engage à restituer à ce dernier,
          au tarif en vigueur au moment de la notification de réversibilité
          , et dès la première demande de celui-ci formulée par lettre recommandée avec accusé de réception dans un
          délai maximal de trois (3) mois à compter de la résiliation, l’ensemble des données lui appartenant sous
          la
          forme de fichiers CSV.
          À l’issue de ce délai de trois (3) mois, aucun document et aucune donnée ne pourront être transmis au
          Partenaire.
        </p>
        <p>
          DAGOBA s’engage à ne conserver une copie des données restituées au Partenaire qu’à des fins de sauvegarde
          et à
          ne plus les utiliser pour quelque autre raison que ce soit.
        </p>
        <p>
          Le Partenaire collaborera activement avec DAGOBA afin de faciliter la récupération de ses données.
        </p>
        <h2>
          Article 14 : Loi applicable et juridiction compétente
        </h2>
        <p>
          Les présentes Conditions générales sont régies par le droit français.
        </p>
        <p>
          En cas de contestation relative à la validité, l’interprétation ou l’exécution des présentes, et après
          l’échec
          d’une tentative de règlement amiable à laquelle les parties s’obligent, ces dernières feront attribution
          de
          juridiction aux Tribunaux compétents de Paris.
        </p>
      </Box>
      <Footer intl={intl}/>
    </div>
  </>);

}

export default TermsOfService;