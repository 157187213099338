import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import { Box, Collapse } from '@mui/material';
import './MarkdownDocumentation.scss';
import { IntlShape } from "react-intl";
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import PrivacyTipIcon from '@mui/icons-material/PrivacyTip';
import WarningIcon from '@mui/icons-material/Warning';
import ReportIcon from '@mui/icons-material/Report';

type TocItem = {
  id: string;
  text: string;
  level: number;
  children?: TocItem[];
};

type MarkdownDocumentationProps = {
  intl: IntlShape;
  markdownContentKey: string;
  allowPrivateContent: boolean;
};

// Define the type for the icons
type IconDefinition = {
  component: React.ElementType;
  color: string;
};

// Restrict the keys to a specific set of icon names
const ICONS: Record<'TipsAndUpdates' | 'PrivacyTip' | 'Warning' | 'Report', IconDefinition> = {
  TipsAndUpdates: { component: TipsAndUpdatesIcon, color: 'gold' },
  PrivacyTip: { component: PrivacyTipIcon, color: 'blue' },
  Warning: { component: WarningIcon, color: 'orange' },
  Report: { component: ReportIcon, color: '#4269af' },
};


const MarkdownDocumentation: React.FC<MarkdownDocumentationProps> = (
  { intl, markdownContentKey, allowPrivateContent }
) => {
  const markdownContentTranslated = intl.formatMessage({ id: markdownContentKey });
  const [expandedLevels, setExpandedLevels] = useState<{ [key: string]: boolean }>({});
  const [processedContent, setProcessedContent] = useState<string>('');
  const [toc, setToc] = useState<TocItem[]>([]);

  const processMarkdownContent = (markdown: string): { content: string; toc: TocItem[] } => {
    // Remove escaped characters for << and >>
    const unescapedMarkdown = markdown.replace(/\\</g, '<').replace(/\\>/g, '>');

    let headingIndex = 0;
    const toc: TocItem[] = [];
    const stack: { item: TocItem; level: number }[] = [];
    const privateTagRegex = /begin-private([\s\S]*?)end-private/gs;
    const iconTagRegex = /<<(\w+)>>/g; // Matches <<IconName>>

    // Process private content first
    const processedMarkdown = unescapedMarkdown
      .replace(privateTagRegex, (_, content) => {
        if (allowPrivateContent) {
          return content.trim(); // Keep the private content
        }
        return `<div class="private-placeholder">${intl.formatMessage({ id: 'help-page-private-content' })}</div>`; // Replace with placeholder
      })
      .replace(iconTagRegex, (_, iconName) => {
        if (iconName in ICONS) {
          return `{{icon-${iconName}}}`; // Replace with a unique placeholder
        }
        return `<span class="invalid-icon">Invalid Icon: ${iconName}</span>`;
      });;

    // Process headings and build TOC
    const lines = processedMarkdown.split('\n');
    const processedLines = lines.map((line) => {
      // Handle headings (h1 to h4)
      const match = /^(#{1,4})\s(.+)$/.exec(line);
      if (match) {
        const level = match[1].length;
        const text = match[2].trim();
        const id = `heading-${++headingIndex}`;

        const tocItem: TocItem = { id, text, level };

        // Maintain hierarchical structure in the TOC
        while (stack.length > 0 && stack[stack.length - 1].level >= level) {
          stack.pop();
        }

        if (stack.length === 0) {
          toc.push(tocItem);
        } else {
          const parent = stack[stack.length - 1].item;
          parent.children = parent.children || [];
          parent.children.push(tocItem);
        }

        stack.push({ item: tocItem, level });

        // Inject ID into the heading
        return `<h${level} id="${id}">${text}</h${level}>`;
      }

      return line; // Return unchanged lines
    });

    return { content: processedLines.join('\n'), toc };
  };

  useEffect(() => {
    const { content, toc } = processMarkdownContent(markdownContentTranslated);
    setProcessedContent(content);
    setToc(toc);

    // Initialize expanded levels
    const initialExpandedLevels: { [key: string]: boolean } = {};
    toc.forEach((item) => {
      if (item.level === 1) {
        initialExpandedLevels[item.id] = true;
      }
    });
    setExpandedLevels(initialExpandedLevels);
  }, [markdownContentTranslated, allowPrivateContent]);

  const handleScrollToContent = (id: string) => {
    const target = document.getElementById(id);
    if (target) {
      target.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  const toggleExpand = (id: string) => {
    setExpandedLevels((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  const renderTocItems = (items: TocItem[]) => {
    return items.map((item) => (
      <div key={item.id} className={`toc-item toc-level-${item.level}`}>
        <div className="toc-header">
          {item.children ? (
            <div className="toc-icon" onClick={() => toggleExpand(item.id)}>
              {expandedLevels[item.id] ? '-' : '+'}
            </div>
          ) : (
            <div className="toc-icon">{item.level === 4 && '•'}</div>
          )}
          <span className="toc-link" onClick={() => handleScrollToContent(item.id)}>
            {item.text}
          </span>
        </div>
        {item.children && (
          <Collapse in={!!expandedLevels[item.id]} timeout="auto">
            {renderTocItems(item.children)}
          </Collapse>
        )}
      </div>
    ));
  };

  return (
    <Box className="markdown-documentation">
      <Box className="toc" component="nav">
        {renderTocItems(toc)}
      </Box>
      <Box className="content">
        <Markdown
          options={{
            overrides: {
              p: {
                component: ({ children }: { children: React.ReactNode }) => {
                  const content = React.Children.map(children, (child) => {
                    if (typeof child === 'string') {
                      // Replace placeholders with actual icon components
                      const parts = child.split(/({{icon-\w+}})/g); // Split by placeholders
                      return parts.map((part, index) => {
                        const match = /{{icon-(\w+)}}/.exec(part);
                        if (match) {
                          const iconName = match[1];
                          const icon = ICONS[iconName as keyof typeof ICONS];
                          if (icon) {
                            const IconComponent = icon.component;
                            return (
                              <span className="icon" key={`${iconName}-${index}`} style={{color: icon.color}}>
                                <IconComponent />
                              </span>
                            );
                          }
                        }
                        return part; // Return plain text or unmatched parts
                      });
                    }
                    return child;
                  });

                  return <p>{content}</p>;
                },
              },
            },
          }}
        >
          {processedContent}
        </Markdown>
      </Box>
    </Box>
  );
};

export default MarkdownDocumentation;
