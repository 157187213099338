import React from 'react';
import {ILogoInfos} from "../logos/logosSlice";
import {IAddonInfos, ICollectionProduct, IImageAddon} from "./boutiqueSlice";
import {FormattedMessage} from "react-intl";
import {LogoAddonConfig} from "./LogoAddonConfig";

export const LogoAddonsList = (props: {
  collectionProduct: ICollectionProduct,
  imageAddons: IImageAddon[],
  colorLogoAddons: IAddonInfos[],
  colorIndex: number,
  firstColorName: string,
  excludedAddons: string[],
  setAddonInfos: (colorIndex: number, addonInfos: IAddonInfos) => void,
  logos: ILogoInfos[],
  editMode: boolean
}) => {

  const {collectionProduct, imageAddons, colorLogoAddons, colorIndex, firstColorName, excludedAddons, setAddonInfos, logos, editMode} = props;

  // console.log('Rendering LogoAddonsList of color ', colorIndex);
  
  return (
    <>
      {imageAddons.map((imageAddon, i) => {

        const addonInfos = colorLogoAddons.find(addon => addon.addonId === imageAddon.id) || {
          addonId: imageAddon.id,
          name: imageAddon.name,
          logoId: 0,
          logoVersionId: 0,
          zoom: 0,
          optional: false
        };

        return (
          <LogoAddonConfig key={`${i}-${imageAddon.id}`} index={i}
                           collectionProduct={collectionProduct}
                           imgAddon={imageAddon}
                           addonInfos={addonInfos}
                           colorIndex={colorIndex}
                           disabled={excludedAddons.includes(imageAddon.name)}
                           setAddonInfos={setAddonInfos} logos={logos}
                           editMode={editMode} />
        );
      })}
      <div>
        {colorIndex > 0 && <div className="choose-logo-position">
          (<FormattedMessage id='product.choose-addon-on-first-image' values={{color: firstColorName}} />)
        </div>}
      </div>
    </>
  )
}
