import React, {useState, useEffect } from 'react';
import {Paper, Table, TableBody, TableCell, tableCellClasses, TableContainer, TableHead, TableRow} from "@mui/material";
import {
  ILogoInfos,
  logoVersionUsageProducts,
  resizeLogoVersion,
  defineSizeLogoVersion,
  logoVersionsChangesSelector,
  ILogoVersionChange,
  addLogoVersion, defineNameLogoVersion, TECHNIQUE_DTF, removeLogoVersion
} from "./logosSlice";
import { styled } from '@mui/material/styles';
import {FormattedMessage, useIntl} from "react-intl";
import {PriceFormatted} from "../../app/product";
import {BoudaToolTip} from "../utils/BoudaToolTip";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import InlineEdit from "../utils/InlineEdit";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from "@mui/icons-material/Delete";
// @ts-ignore
import variables from "../../styles/_variables.scss";

function LogoVersionsCard(props: {
  logo: ILogoInfos,
  superAdmin: boolean,
  impersonating: boolean,
  saving: boolean
}) {

  const intl = useIntl();
  const logoVersionsChanges = useSelector(logoVersionsChangesSelector);
  const dispatch = useDispatch<AppDispatch>();

  const {logo, superAdmin, impersonating, saving} = props;

  const logoInDTF = logo && logo.technique === TECHNIQUE_DTF;
  const resizeable = !saving && (logoInDTF || impersonating);

  const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,
      backgroundColor: variables.dagobaBlue,
      // color: theme.palette.common.white,
      color: '#FFFFFF',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({theme}) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  const versionInformation = (logoVersionId: number) => {
    const usage = logoVersionUsageProducts(logo, logoVersionId)
    if (usage.length === 0) {
      return '';
    } else {
      return (intl.formatMessage({id: "logos.usage"}) + `<ul>${usage}</ul>`)
    }
  }

  const defineName = (logoVersionId: number, name: string) => {
    dispatch(defineNameLogoVersion({id: logoVersionId, newName: name}))
  }

  const defineSize = (logoVersionId: number, sizeText: string) => {
    dispatch(defineSizeLogoVersion({id: logoVersionId, sizeText: sizeText}))
  }

  const resize = (logoVersionId: number, direction: string) => {
    const heightChange = direction === 'up' ? 1 : -1;
    dispatch(resizeLogoVersion({id: logoVersionId, heightChange: heightChange}))
  }

  const resizeButtons = (logoVersionId: number, used: boolean, sizeCode: number) => {
    return (
      <Box sx={{display: 'flex', justifyContent: 'center'}}>
        <IconButton size='small' sx={{padding: 0}} disabled={saving} onClick={() => resize(logoVersionId, 'up')}>
          <KeyboardArrowUpIcon/>
        </IconButton>
        <IconButton size='small' sx={{padding: 0}} disabled={saving} onClick={() => resize(logoVersionId, 'dn')}>
          <KeyboardArrowDownIcon/>
        </IconButton>
        {!used && sizeCode === 0 &&
          <IconButton size='small' sx={{padding: 0}} disabled={saving}
			      onClick={() => dispatch(removeLogoVersion({id: logoVersionId}))}><DeleteIcon/>
	        </IconButton>
        }
      </Box>
    )
  }

  const usedExplanation = (usage: string) => <>
    {!!usage && <div dangerouslySetInnerHTML={{__html: usage}}/> || <FormattedMessage id="logos.not-used-version" />}
  </>

  const stylesForUsed = (used: boolean,
                         fieldNames: string[],
                         changes: ILogoVersionChange[]) => {
    const color = fieldNames.some((field) =>  changes.find((c) => c.field == field)) ?
      'red' : 'black';
    return (used ?
      {cursor: 'pointer', fontWeight: 'bold', color: color} :
      {cursor: 'auto', fontWeight: 'normal', color: color})
  };

  const nameColor = (allPrintFiles: string) => {
    const printFiles = allPrintFiles.split('|')
    if (printFiles.find((file) => file.startsWith('BOUDA'))) {
      return '#40A040';
    } else {
      if (printFiles.find((file) => file.endsWith('.png'))) {
        return '#295bff';
      } else {
        return '#FF0000';
      }
    }
  }

  const stylesForUsedAndFiles = (used: boolean, fieldNames: string[], changes: ILogoVersionChange[], allPrintFiles: string) => (
    {
      ...stylesForUsed(used, fieldNames, changes),
      color: superAdmin ? nameColor(allPrintFiles) : 'black'
    }
  )

  return (
    <TableContainer id='logo-versions' component={Paper}>
      {/*<Table sx={{ minWidth: 300 }} size="small">*/}
      <Table size="small" className='logo-table'>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" width={80}><FormattedMessage id="global.name" /></StyledTableCell>
            <StyledTableCell align="center"><FormattedMessage id="global.width_height_mm" /></StyledTableCell>
            {resizeable && <StyledTableCell align="center"><FormattedMessage id="logos.resize" /></StyledTableCell>}
            {logoInDTF && <StyledTableCell align="center">Surface</StyledTableCell>}
            <StyledTableCell align="center" width={38}><FormattedMessage id="global.price" /></StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {logo.versions.filter((lv) => !lv.deleted).map((logoVersion) => ({
            ...logoVersion,
            usage: versionInformation(logoVersion.id),
            changes: logoVersionsChanges.filter((change) => change.logoVersionId === logoVersion.id) || []
          })).map((lvWithInfos) => (
            <BoudaToolTip key={`tip-row-lv-${lvWithInfos.id}`} title={
              usedExplanation(lvWithInfos.usage) || (superAdmin &&
              lvWithInfos.boutiqueLogoPrintFiles.split('|').map((line, index) => (
                <div key={`boutique-logo-${lvWithInfos.id}-${index}`}>{line}</div>
              )))
            } placement="left" sx={{textAlign: 'left'}}>
              <StyledTableRow key={`row-lv-${lvWithInfos.id}`}>
                <StyledTableCell align="left" width={80}
                                 sx={stylesForUsedAndFiles(!!lvWithInfos.usage, ['name'],
                                   lvWithInfos.changes, lvWithInfos.boutiqueLogoPrintFiles)}>

                  {lvWithInfos.id > 0 && lvWithInfos.name ||

		                  <InlineEdit key={`logo-v-${lvWithInfos.id}-name`} maxWidth={30} widthPixels={100}
		                              text={lvWithInfos.name} color='red'
		                              onSetText={(value: string) => defineName(lvWithInfos.id, value)} />

                  }
                </StyledTableCell>

                <StyledTableCell align="center"
                                 sx={stylesForUsed(!!lvWithInfos.usage, ['width', 'height'], lvWithInfos.changes)}>

                  {resizeable && !saving ?
                    <InlineEdit key={`logo-v-${lvWithInfos.id}-dims`} maxWidth={7} widthPixels={60}
                              text={`${lvWithInfos.width}x${lvWithInfos.height}`}
                              tip={intl.formatMessage({id: "logos.dimensions-tip"})}
                              onSetText={(value: string) => defineSize(lvWithInfos.id, value)} /> :
                    <>{lvWithInfos.width}x{lvWithInfos.height}</>
                  }

                </StyledTableCell>

                {resizeable &&
                  <StyledTableCell align="center" sx={stylesForUsed(!!lvWithInfos.usage, [], lvWithInfos.changes)}>
                    {resizeButtons(lvWithInfos.id, !!lvWithInfos.usage, lvWithInfos.sizeCode)}
                  </StyledTableCell>
                }

                {logo.technique === 0 &&
                    <StyledTableCell align="center"
                                     sx={stylesForUsed(!!lvWithInfos.usage, ['areaCode'], lvWithInfos.changes)}>
                  {`${Math.round(lvWithInfos.width * lvWithInfos.height/100)}cm² (${lvWithInfos.areaCode})`}
                  </StyledTableCell>
                }

                <StyledTableCell align="right" width={35} sx={stylesForUsed(!!lvWithInfos.usage, ['priceCents'], lvWithInfos.changes)}>
                  {PriceFormatted(lvWithInfos.priceCents)}
                </StyledTableCell>
              </StyledTableRow>
            </BoudaToolTip>
          ))}
          {logoInDTF &&
          <StyledTableRow key={`row-lv-add-version`}>
            <StyledTableCell align="left" width={80} colSpan={5}>
              <Box sx={{display: 'flex'}}>
                <Box><FormattedMessage id="logos.add-version" /></Box>
                <IconButton size='small' sx={{padding: 0, ml: '5px'}} disabled={saving}
                            onClick={() => dispatch(addLogoVersion({newName: intl.formatMessage({id: "logos.new-version"})}))}>
                  <AddCircleIcon fontSize='small' />
                </IconButton>
              </Box>
            </StyledTableCell>
          </StyledTableRow>}
        </TableBody>
      </Table>
    </TableContainer>
  )

}

export default LogoVersionsCard;