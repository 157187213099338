import './website.scss';
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import Footer from "./Footer";
import MarkdownDocumentation from "../utils/MarkdownDocumentation";
import {useSelector} from "react-redux";
import {RootState} from "../../app/store";
import {impersonatingUser, ROLE_BOUTIQUE} from "../sessions/sessionSlice";
import {Helmet} from "react-helmet-async";
import {getH1Headers} from "../../app/markdown";
const HelpPage = () => {

  const intl = useIntl();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
    (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))

  return (<>
      <Helmet>
        <meta name="title" content={`DAGOBA - ${intl.formatMessage({id: 'help-page-title'})}`} />
        <meta name="description" content={getH1Headers(intl.formatMessage({id: 'help-page-content'}))} />
      </Helmet>
      <div className='website'>
        <Box className='section help-center'>
          <h1 className='title'>
            {intl.formatMessage({id: 'help-page-title'})}
          </h1>
          <MarkdownDocumentation
            intl={intl}
            markdownContentKey={intl.formatMessage({id: 'help-page-content'})}
            allowPrivateContent={userBoutique || false}
          />
        </Box>
        <Footer intl={intl}/>
      </div>
    </>
  )

}

export default HelpPage;