import React, { useEffect } from 'react';

const FacebookLikeButton: React.FC = () => {
  useEffect(() => {
    // Ensure the Facebook SDK is loaded and parse the widget
    if (window.FB && window.FB.XFBML) {
      window.FB.XFBML.parse();
    }
  }, []);

  return (
    <div>
      <div
        className="fb-like"
        data-href="https://www.facebook.com/dagobaapp/"
        data-width=""
        data-layout="box_count"
        data-action="like"
        data-size="large"
        data-share="true"
      ></div>
    </div>
  );
};

export default FacebookLikeButton;
