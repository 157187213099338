import './website.scss';
import React, {useEffect} from "react";
import Box from "@mui/material/Box";
import {useIntl} from "react-intl";
import Footer from "./Footer";
import {Helmet} from "react-helmet-async";
const TermsOfSale = () => {

  const intl = useIntl();

  return (<>
    <Helmet>
      <meta name="title" content={`DAGOBA - ${intl.formatMessage({id: 'footer.cgv'})}`}/>
      <meta name="description" content="Conditions Générales de Vente sur les boutiques" />
    </Helmet>
    <div className='website'>
      <Box className='section tos'>
        <h1>
          Conditions Générales de Vente sur les boutiques
        </h1>
        <h2>
          Champ d’application des présentes CGV
        </h2>
        <p>
          Les présentes Conditions Générales de Vente et d’utilisation (ci-après les «
          <strong>
            Conditions générales
          </strong>
          ») ont pour objet de prévoir les conditions d’utilisation et de vente à distance et de livraison des produits
          (ci-après les « Produits ») proposés à la vente sur les boutiques Partenaires (ci-après la « Boutique »)
          par DAGOBA FRANCE – (ci-après «DAGOBA»), société par actions simplifiée au capital de 50.000 €,
          immatriculée au RCS de Paris sous le numéro 849 160 700, numéro de TVA intracommunautaire FR18849160700,
          dont le siège social est situé 10 rue de Penthièvre – 75008 Paris, FRANCE.
        </p>
        <p>
          Le «
          <strong>
            Client
          </strong>
          » désigne, au sein des Conditions générales, tout consommateur effectuant un achat de Produits sur la Boutique, à
          titre privé, à l’exclusion de tout achat pour revente.
        </p>
        <p>
          L’«
          <strong>
            Utilisateur
          </strong>
          » désigne, au sein des Conditions générales, toute personne naviguant sur la Boutique, qu’elle soit Client ou non.
        </p>
        <p>
          Le terme «
          <strong>
            Jour ouvré
          </strong>
          » désigne, au sens des Conditions générales, tous les jours de la semaine à l’exception des samedis, dimanches
          et jours fériés.
        </p>
        <p>
          En naviguant sur la Boutique, chaque Utilisateur s’engage à lire et à accepter pleinement et entièrement les
          Conditions générales. Ainsi, à la date de la première connexion, les Conditions générales sont réputées lues,
          comprises et acceptées sans réserve par l’Utilisateur.
        </p>
        <p>
          DAGOBA se réserve le droit de compléter, de modifier et de faire évoluer les présentes Conditions générales à
          tout moment. En tout état de cause, toute modification significative sera notifiée à l’Utilisateur par
          l’introduction d’un pop-up sur la Boutique ou par mail. Elles sont par ailleurs librement accessibles et
          consultables par toute personne naviguant ou utilisant la Boutique.
        </p>
        <h2>
          Article 1 : Disponibilité
        </h2>
        <p>
          DAGOBA propose sur la Boutique la vente en ligne de Produits dont certains peuvent être personnalisés à la demande
          du Client.
        </p>
        <p>
          Chacune des fiches descriptives des Produits en vente sur la Boutique établit une description détaillée du Produit
          concerné et de ses caractéristiques essentielles.
        </p>
        <p>
          Le Client est invité à prendre connaissance, à réception des Produits, des informations jointes aux Produit ou
          portées sur leurs emballages, étant rappelé qu’il est seul responsable du choix des Produits et de leur
          utilisation.
        </p>
        <p>
          Sauf offre exceptionnelle, les offres de Produits et de prix sont valables tant qu’elles sont visibles sur la
          Boutique et dans les conditions de disponibilité fournies au moment de la passation de commande.
        </p>
        <p>
          En cas d’indisponibilité temporaire ou définitive, totale ou partielle du (ou des) Produit(s) commandé(s), le
          Client sera informé au plus tôt par e-mail de la possibilité de demander l’annulation totale ou partielle de
          sa commande.
        </p>
        <p>
          Dans l’hypothèse où le Client souhaiterait annuler tout ou partie de sa commande, DAGOBA procèdera au
          remboursement correspondant dans les conditions visées à l’article 5 ci-après.
        </p>
        <h2>
          Article 2 : Commande
        </h2>
        <h3>
          2.1 Articles
        </h3>
        <p>
          DAGOBA propose au Client des produits aux couleurs de DAGOBA et dont certains sont personnalisables en
          fonction des souhaits du Client.
        </p>
        <h3>
          2.2 Comment passer une commande ?
        </h3>
        <p>
          Le Client intéressé par des Produits sur la Boutique suit le processus suivant afin d’établir sa commande :
          • il choisit son (ou ses) article(s) parmi les Produits proposés à la vente sur la Boutique ;
          • il effectue le cas échéant la personnalisation supplémentaire de son (ou ses) Produit(s) dans les conditions
          prévues aux présentes Conditions générales ;
          • il valide son panier à partir duquel il est habilité à ajouter ou retirer des Produits ;
          • il choisit son mode de livraison. Il est précisé ici que les coûts peuvent varier selon le mode de livraison
          choisi ;
          • il choisit son mode de paiement parmi les moyens de règlement proposés et effectue sa demande de paiement.
          • il saisit toutes les informations personnelles d’expédition nécessaires au traitement de sa commande ;
        </p>
        <p>
          Le paiement de la commande formalise l’agrément par le Client du contenu et des conditions de la commande et
          vaut acceptation irrévocable des Conditions générales.
        </p>
        <p>
          DAGOBA envoie au Client un email confirmant l’enregistrement de sa commande. Sans autre message de DAGOBA, cet
          email vaut acceptation par DAGOBA de la commande du Client et forme ainsi le contrat de vente entre les
          parties.
        </p>
        <p>
          Dès que la commande est en cours d'expédition, DAGOBA envoie un e-mail d'expédition de la commande au Client.
        </p>
        <h3>
          2.3 Nos Prix
        </h3>
        <p>
          Les prix affichés sur la Boutique sont indiqués en euros toutes taxes comprises, à l’exclusion des frais de
          conditionnement et de transport, et s’appliquent à tous les Clients souhaitant être livrés dans un pays de la
          communauté européenne.
        </p>
        <p>
          Sauf offres promotionnelles particulières/spécifiques, les prix des Produits vendus sont ceux figurant sur la
          Boutique au jour de la commande.
        </p>
        <p>
          Les prix comprennent le Produit choisi par le Client et la (ou les) personnalisation(s) demandée(s) par lui.
        </p>
        <p>
          La TVA est appliquée au taux en vigueur au jour de la commande.
        </p>
        <h3>
          2.4 Quels sont les moyens de paiement ?
        </h3>
        <p>
          Le Client peut régler ses achats par Carte Bancaire (transaction sécurisée) au moment de la commande.
        </p>
        <p>
          Le Client peut aussi payer par virement bancaire sur demande.
        </p>
        <p>
          Dans le cas de contrôles anti-fraude ayant pour but de sécuriser les transactions de ses clients, DAGOBA sera
          en droit de demander au Client de fournir un ou plusieurs justificatifs de domicile et/ou copie de carte
          d'identité par e-mail ou par fax avant de valider sa commande.
        </p>
        <p>
          La commande ne sera alors définitive qu'à réception par DAGOBA de ces documents et envoi de l'e-mail de
          confirmation. A défaut de la réception de ces pièces ou si celles-ci ne permettent pas d'identifier de manière
          certaine l'identité de l'auteur de la commande et la réalité de sa domiciliation, DAGOBA se réserve le droit
          de ne pas accepter la commande.
        </p>
        <p>
          DAGOBA se réserve également le droit de refuser une commande s'il existe un litige antérieur ou bien un nombre
          de produits anormalement élevé.
        </p>
        <h3>
          2.5 Preuve de la commande/paiement
        </h3>
        <p>
          Sauf preuve contraire, les registres informatiques de DAGOBA et ceux de ses prestataires, conservés dans des
          conditions raisonnables de sécurité, constitueront la preuve de l'ensemble des transactions passées entre
          DAGOBA et le Client.
        </p>
        <p>
          Conformément à la règlementation, DAGOBA procèdera à un archivage des commandes sur un support fiable et
          durable pouvant être produit à titre de preuve.
        </p>
        <p>
          En tout état de cause, DAGOBA recommande au Client de conserver l'e-mail de validation de sa commande.
        </p>
        <h3>
          2.6 Cartes cadeaux
        </h3>
        <p>
          Le Client peut faire l'achat sur une boutique d'une (ou plusieurs) carte(s) "cadeau(x)". Une fois achetée, la
          carte cadeau est utilisable uniquement sur la Boutique et est valable pendant une durée de douze (12) mois à
          compter de son achat. Elle ne peut s'utiliser qu'en une seule fois.
        </p>
        <p>
          La carte cadeau ne peut être ni échangée, ni revendue, ni remboursée, même partiellement, ni portée au crédit
          d'une carte ou d'un compte bancaire ou d'une autre carte cadeau, ni faire l'objet d'un escompte et ne peut
          donner lieu à aucun rendu de monnaie. Elle n'est ni remplacée, ni remboursée en cas de perte.commande
        </p>
        <h2>
          Article 3 : Livraison
        </h2>
        <p>
          DAGOBA livre uniquement en France métropolitaine, ou en Belgique (uniquement quand la boutique est configurée
          pour la Belgique).
        </p>
        <p>
          Les frais d’expédition sont calculés en fonction du montant de la commande et comprennent le traitement, le
          conditionnement et l’envoi des Produits.
        </p>
        <h3>
          <i>
            Quels sont les délais de livraison ?
          </i>
        </h3>
        <p>
          Le délai maximum de livraison est généralement de 5 semaines, incluant le passage par notre atelier pour la
          personnalisation. Ce délai s'applique à partir de la réception par le Client de l'e-mail de validation de la
          commande envoyé par DAGOBA. Cependant, pour la plupart des Produits, la livraison a lieu en 1 à 2 semaines, en
          fonction des stocks des fournisseurs de DAGOBA. Certains Produits peuvent être en rupture de stock momentanée
          et avoir des délais de livraison plus longs.
        </p>
        <p>
          En cas de commande dont l’un (ou plusieurs) des Produit(s) se révélerai(en)t indisponible(s), le Client sera
          informé et pourra, à sa convenance, choisir l’une des options suivantes :
        </p>
        <ul>
          <li>
            <p>
              Annuler la commande de l’article indisponible, suivant les modalités fixées par l’article L.216-2 du Code
              de la consommation ;
            </p>
          </li>
          <li>
            <p>
              En cas de commande de plusieurs Produits, si l’un des Produits se révèle indisponible dans les délais
              annoncés sur la Boutique, DAGOBA informera alors le Client de ce délai supplémentaire. L’ensemble de la
              commande ne sera traité et expédié que lorsque l’ensemble des Produits de cette commande sera disponible.
              Le Client peut également opter pour une annulation partielle de sa commande concernant les Produits
              indisponibles, suivant les modalités susvisées.
            </p>
          </li>
        </ul>
        <h3>
          <i>
            Cas de non retrait du colis en Point Relais dans les délais impartis par La Poste
          </i>
        </h3>
        <p>
          Pour les livraisons en Point Relais, en cas de non retrait du colis par le Client dans les délais impartis par
          La Poste, le colis sera retourné à DAGOBA. DAGOBA peut ré-expédier à nouveau le colis moyennant le paiement
          des nouveaux frais d'expédition, au même montant que le premier envoi, et moyennant un forfait supplémentaire
          de 7,50 € TTC pour le traitement du colis au centre d'expédition de DAGOBA.
        </p>
        <p>
          Le non retrait d'un colis en Point Retrait ne constitue en aucun cas l'annulation de la vente et ne peut
          donner lieu à aucun remboursement, notamment en vertu de l’article L.221-28 du Code de la consommation
          concernant la fourniture de biens personnalisés.
        </p>
        <h2>
          Article 4 : Réserve de propriété
        </h2>
        <p>
          De convention expresse, DAGOBA se réserve la propriété des Produits jusqu'au jour de leur parfait paiement,
          conformément aux termes de la loi n°80 336 du 1er mai 1980, relative à la réserve de propriété.
        </p>
        <p>
          Cette disposition ne fait pas obstacle au transfert au bénéfice du Client des risques liés aux Produits
          vendus, ce transfert intervenant dès la livraison des Produits à l’adresse indiquée par le Client au moment de
          sa commande, ou encore au moment du retrait des Produits auprès de La Poste.
        </p>
        <h2>
          Article 5 : Rétractation
        </h2>
        <h3>
          5.1 - Information générale
        </h3>
        <p>
          Conformément aux dispositions des articles L.221-18 et suivants du Code de la consommation, DAGOBA accorde au
          Client un délai de rétractation de quatorze (14) jours à compter de la réception de sa commande sans avoir à
          justifier de motifs ni à payer de pénalité.
        </p>
        <p>
          Il est toutefois rappelé qu’en vertu de l’article L.221-28 du Code de la consommation, ce droit n’est pas
          applicable à la fourniture de biens personnalisés. Ainsi, seuls les articles ne comportant pas de logo ou de
          personnalisation sont concernés par le droit de rétractation visé ci-dessus. Par conséquent, tous les articles
          ayant un logo ou une personnalisation ne sont pas remboursables ou échangeables.
        </p>
        <p>
          Toute demande de retour parvenue à DAGOBA au-delà du délai de rétractation de quatorze (14) jours ne pourra
          pas être acceptée, sauf en cas de non-conformité ou de vice caché (voir article 6 ci-après).
        </p>
        <p>
          Si ce délai expire un samedi, un dimanche ou un jour férié, il sera prolongé jusqu’au premier Jour ouvré
          suivant.
        </p>
        <p>
          Dans le cas de commandes multiples, à savoir de plusieurs Produits en même temps, le délai de rétractation
          courra à partir de la réception du dernier Produit dans l’hypothèse de l’indisponibilité d’un Produit et du
          fractionnement de la commande en plusieurs envois.
        </p>
        <h3>
          5-2 Modalités de rétractation (uniquement pour les articles sans logo et sans personnalisation)
        </h3>
        <p>
          Pour exercer son droit de rétractation, le Client doit notifier à DAGOBA sa décision de se rétracter au moyen
          d’une déclaration dénuée d’ambiguïté à l’adresse suivante :
        </p>
        <p>
          - par e-mail : contact@dagoba.app
          - par téléphone au Service Client (FR: +33 1 87 20 70 07)
        </p>
        <p>
          Le Client devra renvoyer ou rendre le Produit uniquement à l’adresse qui lui sera communiquée par DAGOBA en
          fonction du Produit. Le Client ne doit jamais envoyer le Produit au siège de la société.
        </p>
        <p>
          Ce renvoi doit être fait dans un délai n’excédant pas quatorze (14) jours après la notification de la
          rétractation et de la notification par DAGOBA de l'adresse à laquelle le Client doit envoyer le Produit.
        </p>
        <p>
          Le Client devra prendre en charge les frais directs de renvoi du Produit.
        </p>
        <p>
          L’exercice de son droit de rétractation par le Client ne sera accepté par DAGOBA que pour les Produits en
          parfait état de revente et accompagnés de la facture d’achat et toujours porteurs de l’étiquette.
        </p>
        <p>
          Les Produits retournés abîmés, salis ou ayant été portés (sauf dans le cadre d’un simple et bref essayage) ou
          utilisés à l’extérieur par le Client, ou dont les étiquettes auront été retirées, ne seront pas repris.
        </p>
        <h3>
          5.3 - Remboursement
        </h3>
        <p>
          En cas de rétractation, le Client est remboursé de tous les paiements reçus, y compris les frais de livraison
          standards (c’est-à-dire correspondant à la livraison la moins onéreuse que DAGOBA propose).
        </p>
        <p>
          Dans les cas de retour tels que prévus aux articles 6.1 et 6.2 ci-après, les frais de retour seront remboursés
          au Client qui devra cependant fournir à DAGOBA les justificatifs nécessaires (facture transporteur).
        </p>
        <p>
          Ce remboursement s’effectue dans un délai n’excédant pas quatorze (14) jours à compter de la réception du bien
          retourné par le Client ou de la preuve de son expédition.
        </p>
        <p>
          Le Client est remboursé par le même moyen de paiement que celui utilisé lors de la transaction initiale.
        </p>
        <h2>
          Article 6 : Garantie légale de conformité / SAV
        </h2>
        <h3>
          6-1 Conformité et vices apparents
        </h3>
        <p>
          Afin d’éviter tout désagrément au Client, le DAGOBA vérifie avant leur expédition la conformité des Produits
          avec la commande.
        </p>
        <p>
          Le Code de la consommation encadre la garantie légale de conformité de la manière suivante (art. L.217-4 du
          Code de la consommation) :
          « Le vendeur livre un bien conforme au contrat et répond des défauts de conformité existant lors de la
          délivrance. Il répond également des défauts de conformité résultant de l'emballage, des instructions de
          montage ou de l'installation lorsque celle-ci a été mise à sa charge par le contrat ou a été réalisée sous sa
          responsabilité ».
        </p>
        <p>
          Le Client dispose donc, dans un tel cas, d’un délai de deux (2) ans, à partir de la livraison d’un Produit,
          pour notifier à DAGOBA, par e-mail adressé au Service Clientèle de DAGOBA, toutes réserves sur la conformité
          des Produits livrés.
        </p>
        <p>
          DAGOBA lui adressera ensuite un e-mail de confirmation de son acceptation du retour du ou des Produits, ainsi
          que le cas échéant un numéro de retour qu’il devra faire figurer sur le colis lors du renvoi du Produit
          non-conforme ou défectueux, ainsi que l'adresse à laquelle retourner le Produit.
        </p>
        <p>
          Les Produits doivent être retournés en parfait état de revente.
        </p>
        <p>
          Les Produits retournés abîmés ou salis, ou ayant été portés (sauf dans le cadre d’un simple et bref essayage)
          ou utilisés à l’extérieur par le Client, ou dont les étiquettes auront été retirées, ne seront pas repris.
        </p>
        <p>
          Le Client veillera dans ces conditions à prendre particulièrement soin des Produits entre le moment de leur
          livraison et celui de leur retour.
        </p>
        <p>
          Les Produits devront être retournés dans leur emballage d’origine, à l’adresse indiquée par DAGOBA pour le
          retour de ce Produit particulier. Le Client ne doit jamais envoyer le Produit au siège de la société mais
          uniquement à l'adresse indiquée par DAGOBA.
        </p>
        <p>
          Dans les cas où le Produit retourné ne respecterait pas les conditions qui précèdent, DAGOBA se réserve le
          droit :
        </p>
        <p>
          (i) de ne pas accepter le retour du Produit, lequel sera tenu à la disposition du Client à ses risques et
          périls, pendant un délai de six (6) mois à compter de la réception du Produit par DAGOBA, sauf dans les cas où
          le Produit aura été reçu dans cet état par le Client ;
        </p>
        <p>
          (ii) de ne pas rembourser le Client dans son intégralité, en déduisant tout montant dû en compensation du
          dommage causé au Produit, dès lors que le dommage se sera réalisé après la livraison au Client.
        </p>
        <p>
          DAGOBA recommande en outre au Client d’obtenir une preuve d’expédition pour tout colis qu’il retourne à DAGOBA
          et d’envoyer le colis par courrier recommandé avec accusé de réception ou par courrier avec déclaration de
          valeur, selon le cas.
        </p>
        <p>
          Toute reprise acceptée par DAGOBA, dans le cas de non-conformité des Produits livrés qui sera constatée par
          DAGOBA, permettra au Client d’obtenir le remplacement gratuit ou le remboursement du (ou des) Produit(s),
          conformément aux dispositions de l’article 5.3 des présentes, à l’exclusion de toute autre indemnité.
        </p>
        <p>
          Dans le cas d’une demande de remboursement du ou des Produit(s), acceptée par DAGOBA, les frais d'envoi et de
          retour desdits Produits seront remboursés sur la base du tarif facturé, DAGOBA se réservant cependant la
          faculté de rembourser au Client le coût d’une livraison standard.
        </p>
        <p>
          Si le Client opte pour un avoir, les frais de port initiaux et le prix du Produit seront crédités sur son
          espace Client, au coût d’une livraison standard.
        </p>
        <p>
          DAGOBA n’acceptera pas les colis expédiés en port dû.
        </p>
        <p>
          Tout risque lié au retour des Produits sera à la charge du Client.
        </p>
        <h3>
          6-2 Vices cachés
        </h3>
        <p>
          DAGOBA est tenu de la garantie légale des vices cachés, au sens de l'article 1641 et suivants du Code Civil
          qui dispose : "Le vendeur est tenu de la garantie à raison des défauts cachés de la chose qui la rendent
          impropre à l'usage auquel on la destine, ou qui diminuent tellement cet usage, que le client ne l'aurait pas
          acquise, ou n'en aurait donné qu'un moindre prix, s'il les avait connus".
        </p>
        <p>
          Le Client dispose en conséquence d’un délai de deux (2) ans, à partir de la découverte d’un vice caché
          affectant les Produits, pour faire parvenir à DAGOBA une demande visant à bénéficier de la garantie contre les
          vices cachés qui affecteraient les Produits.
        </p>
        <p>
          Pour pouvoir bénéficier de la garantie des Produits le Client doit impérativement conserver la facture d'achat
          des Produits.
        </p>
        <p>
          En tout état de cause, le Client qui dénonce l’existence d’un vice caché devra être en mesure de démontrer que
          :
          - le vice était antérieur à la vente ;
          - le Produit a été utilisé dans des conditions en adéquation avec la nature et les caractéristiques du Produit
          ;
          - le vice n’est pas lié au temps, à l’usure normale du Produit.
        </p>
        <p>
          Afin de permettre à DAGOBA d'expertiser lui-même les Produits, il est demandé au Client de bien vouloir les
          laisser à sa disposition suivant les modalités qui lui seront communiquées, étant précisé que DAGOBA
          organisera leur enlèvement à ses frais.
        </p>
        <p>
          Dans le cas où le Client ne souhaiterait pas le remplacement gratuit du Produit, le remboursement sera fait
          dans les conditions visées à l’article 5.3 ci-avant.
        </p>
        <h2>
          Article 7 : Engagements du Client
        </h2>
        <h3>
          7.1 - Respect des droits des tiers
        </h3>
        <p>
          Le Client dispose de la possibilité de personnaliser le ou les Produits choisis comme bon lui semble.
          Cependant, le Client ne peut écrire des textes qui seraient susceptibles de contrevenir aux droits de tiers.
          Sa responsabilité peut être engagée à ce titre. En cas de doute, DAGOBA se réserve le droit de refuser
          l’apposition d’un texte sur le Produit.
        </p>
        <p>
          La confirmation de commande par DAGOBA n’emporte en aucun cas validation de la prestation, notamment du
          contenu à reproduire à la demande du Client.
        </p>
        <h3>
          7.2 - Respect de la morale et des bonnes mœurs
        </h3>
        <p>
          Les textes suivants seront systématiquement refusés par DAGOBA : contenu à caractère vulgaire, diffamatoire,
          injurieux, obscène, raciste ou xénophobe, illégal ou susceptible de l’être, incitant à la consommation
          d’alcool, de tabac ou plus largement tout texte qui serait considéré par DAGOBA comme étant contraire à la
          morale et au droit, ainsi qu’aux valeurs et aux intérêts de la société DAGOBA.
        </p>
        <p>
          DAGOBA refuse également de reproduire tout texte ayant une connotation politique, syndicale ou religieuse
          et/ou visant, par tous moyens, des individus, y compris des personnalités de notoriété publique.
        </p>
        <p>
          Le refus de DAGOBA d’exécuter la prestation demandée n’ouvre dans ce cas droit à aucune demande
          d’indemnisation pour le Client, ce qu’il reconnait de manière expresse.
        </p>
        <h2>
          Article 8 : Responsabilité
        </h2>
        <p>
          Les Produits proposés sont conformes à la législation communautaire en vigueur.
        </p>
        <p>
          DAGOBA ne saurait être tenu pour responsable de l'inexécution du contrat, en cas de force majeure ou d’un cas
          fortuit, de perturbation ou grève totale ou partielle notamment des services postaux et moyens de transport
          et/ou communications, du fait de l’autre partie ou d’un tiers ou de causes extérieures telles que les conflits
          sociaux, la perturbation ou la grève totale ou partielle notamment des services postaux et moyens de transport
          et/ou communications, l’intervention des autorités civiles ou militaires, les catastrophes naturelles, les
          incendies, les dégâts des eaux, le mauvais fonctionnement ou l’interruption du réseau des télécommunications
          ou du réseau électrique.
        </p>
        <p>
          Le Client est seul responsable du choix des Produits et de leur utilisation.
        </p>
        <h2>
          Article 9 : Obligations de l’Utilisateur
        </h2>
        <p>
          L’Utilisateur s’engage à utiliser la Boutique conformément aux Conditions générales et plus généralement à
          l’ensemble des dispositions légales en vigueur.
        </p>
        <p>
          L’Utilisateur s’engage à ne pas porter atteinte à l’intégrité et à la sécurité de la Boutique. Il s’engage notamment
          à ne pas accéder ou tenter d’accéder et/ou de se maintenir frauduleusement en cas d’accès non autorisé sur la Boutique.
        </p>
        <p>
          L’Utilisateur s’engage à ne pas réaliser d’opération ayant pour effet de porter atteinte au bon fonctionnement
          de la Boutique telle que des attaques informatiques (notamment des DoS, DDoS, des attaques SQL ou encore des attaques
          XSS).
        </p>
        <p>
          L’Utilisateur s’engage à fournir des informations exactes et à les mettre à jour en cas de changement de
          situation. Il est également interdit de créer une fausse identité et/ou d’usurper l’identité d’un tiers.
        </p>
        <h2>
          Article 10 : Propriété intellectuelle
        </h2>
        <p>
          L’ensemble des droits de propriété intellectuelle afférents à la Boutique, et plus particulièrement tout contenu de
          ce dernier qui ferait l’objet d’une protection par le Code de la propriété intellectuelle et notamment le
          droit des marques et le droit d’auteur, est détenu par DAGOBA.
        </p>
        <p>
          L’Utilisateur s’interdit à cet égard d’extraire, de représenter, de reproduire ou plus généralement de porter
          atteinte à tout ou partie de la Boutique.
        </p>
        <h2>
          Article 11 : Service Client
        </h2>
        <p>
          Pour toute question relative à un achat effectué sur cette Boutique, le Service Client peut être joint :
          - par e-mail à l'adresse suivante : contact@dagoba.app;
          - par voie de formulaire sur la Boutique.
        </p>
        <h2>
          Article 12 : INTEGRALITE DU CONTRAT
        </h2>
        <p>
          Les Conditions générales sont constituées de l'intégralité des clauses qui les composent. Le fait pour DAGOBA
          de ne pas se prévaloir de l'une quelconque des clauses ne peut valoir renonciation définitive à celles-ci. En
          cas d'invalidité de l'une d'entre elles, les autres clauses demeurent valables.
        </p>
        <h2>
          Article 13 : DROIT APPLICABLE
        </h2>
        <p>
          Les Conditions générales sont soumises au droit français, et seule la version en langue française fait foi en
          vue de leur interprétation.
        </p>
        <p>
          Tout différend découlant de l’exécution ou de l’interprétation des Conditions générales oblige les parties à
          se rapprocher et à tenter de trouver une solution amiable avant de saisir la juridiction compétente.
        </p>
        <p>
          Le Client et l’Utilisateur reconnaissent et acceptent que seuls seront compétents les tribunaux français, en
          application des règles édictées par le code de procédure civile.
        </p>
        <p>
          Lorsque le Client est une personne morale ou physique agissant à titre professionnel, le litige sera porté
          devant la juridiction compétente dans le ressort de la Cour d’appel de Paris nonobstant pluralité de
          défendeurs ou appel en garantie.
        </p>
      </Box>
      <Footer intl={intl}/>
    </div>
  </>);

}

export default TermsOfSale;