import './website.scss';
import React, {useEffect, useRef} from "react";
import Box from "@mui/material/Box";
import {FormattedMessage, useIntl} from "react-intl";
import man from '../../images/website/Man250.png';
import boy from '../../images/website/Boy250.png';
import back from '../../images/website/Back250.png';
import LogoGrid from "../utils/LogoGrid";
import TestimonialsCarousel from "../utils/TestimonialsCarousel";
import {Grid} from "@mui/material";
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import FreeBreakfastIcon from '@mui/icons-material/FreeBreakfast';
import BedtimeIcon from '@mui/icons-material/Bedtime';
import SavingsIcon from '@mui/icons-material/Savings';
import Footer from "./Footer";
import {BOUTIQUE_LOGOS, TESTIMONIALS} from "../../app/website";
import TShirtSvg from "../catalog/category/TShirtSvg";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {To, useNavigate} from "react-router-dom";
import {impersonatingUser, ROLE_BOUTIQUE} from "../sessions/sessionSlice";
import {openDemoBoutique} from "../dashboard/boutiqueSlice";
import Button from "@mui/material/Button";
import {Helmet} from "react-helmet-async";

const HomePage = () => {

  const intl = useIntl();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUser = currentUser && currentUser.id !== undefined;
  const userBoutique = loggedUser && currentUser.role !== undefined &&
    (currentUser.role >= ROLE_BOUTIQUE || impersonatingUser(currentUser))
  const userProduction = loggedUser && currentUser.production;

  function handleNavigate(route: To, event: React.MouseEvent) {
    event?.preventDefault();
    navigate(route);
  }

  // const clickBoutique = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
  const clickBoutique = (event: any) => {
    if (userBoutique) {
      handleNavigate("/dashboard", event)
    } else {
      handleNavigate("/catalog", event)
      dispatch(openDemoBoutique({open: true, loading: false}));
    }
  }

  return (<>
    <Helmet>
      <meta name="title" content={`DAGOBA - ${intl.formatMessage({id: 'home.title'})}`} />
      <meta name="description" content={intl.formatMessage({id: 'home.description'})} />
    </Helmet>
    <div className='website'>
      <Box className='section hero'>
        <Box className='hero-line'>
          <Box className='message line-1'>
            <h2 className='target'>{intl.formatMessage({id: 'home.target'})}</h2>
            <h1 className='title'>{intl.formatMessage({id: 'home.title'})}</h1>
          </Box>
          <Box className='illustration'>
            <img src={man}/>
          </Box>
        </Box>
        <Box className='hero-line'>
          <Box className='message line-2'>
            <h2 className='subtitle'>{intl.formatMessage({id: 'home.subtitle'})}</h2>
            <Box className='cta-container big'>
              {!userProduction &&
              <Button color='success' variant='contained' key='start' className='cta'
                      sx={{display: 'flex', fontSize: 'var(--fs-md)'}} onClick={clickBoutique}>
                <RocketLaunchIcon sx={{mr: 1}}/>
                <FormattedMessage id={`home.${userBoutique ? 'dashboard' : 'cta'}`} />
              </Button>}
            </Box>
          </Box>
          <Box className='illustrations'>
            <Box className='illustration line2 boy'>
              <img src={boy} />
            </Box>
            <Box className='illustration line2'>
              <img src={back} />
            </Box>
          </Box>
        </Box>
        <Box className='hero-line cta'>
          <Box className='cta-container'>
            {!userProduction &&
				        <Button color='success' variant='contained' key='start' className='cta'
				                sx={{display: 'flex', fontSize: 'var(--fs-md)'}} onClick={clickBoutique}>
					        <RocketLaunchIcon sx={{mr: 1}}/>
					        <FormattedMessage id={`home.${userBoutique ? 'dashboard' : 'cta'}`} />
				        </Button>}
          </Box>
        </Box>
      </Box>

      <Box className='section samples'>
        <Box className='title'>
          <h1>{intl.formatMessage({id: 'samples.title'})}</h1>
        </Box>
        <Box className='logos'>
          <LogoGrid images={BOUTIQUE_LOGOS} vertical={3} />
        </Box>
        <Box className='testimonials'>
          <TestimonialsCarousel testimonials={TESTIMONIALS} intl={intl} interval={5000} />
        </Box>
      </Box>

      <Box id='how-it-works' className='section how-it-works'>
        <Box className='title'>
          <Box className='text'>
            <h1>{intl.formatMessage({id: 'how-it-works.title'})}</h1>
          </Box>
        </Box>
        <Box className='two-panels'>
          <Grid container>
            <Grid item xs={12} sm={6} className='you'>
              <Box className='content'>
                <Box className='panel-content'>
                  <Box className='step'>
                    <Box className='step-title'>
                      <PlayCircleFilledWhiteIcon fontSize="large" />{intl.formatMessage({id: 'how-it-works.you-step-1-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.you-step-1-content'})}
                      {/*<span className='cta2' onClick={clickBoutique}>*/}
                      {/*  {intl.formatMessage({id: 'home.cta2'})}*/}
                      {/*</span>*/}
                      {!userBoutique && !userProduction &&
                          <Button color='success' variant='contained' key='start' className='cta2'
                              sx={{display: 'flex', fontSize: 'var(--fs-s)'}} onClick={clickBoutique}>
                            <RocketLaunchIcon sx={{mr: 1}}/>
                            <Box sx={{display: 'flex'}}>
                              <FormattedMessage id="home.cta2"/>
                            </Box>
                          </Button>
                      }
                    </Box>
                  </Box>
                  <Box className='step'>
                    <Box className='step-title tshirt'>
                      {TShirtSvg('2.2rem')}{intl.formatMessage({id: 'how-it-works.you-step-2-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.you-step-2-content'})}
                    </Box>
                  </Box>
                  <Box className='step'>
                    <Box className='step-title'>
                      <RocketLaunchIcon fontSize="large" />{intl.formatMessage({id: 'how-it-works.you-step-3-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.you-step-3-content'})}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6} className='us'>
              <Box className='content'>
                <Box className='panel-content us'>
                  <Box className='step'>
                    <Box className='step-title'>
                      <LocalMallIcon fontSize="large" />{intl.formatMessage({id: 'how-it-works.we-step-1-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.we-step-1-content'})}
                    </Box>
                  </Box>
                  <Box className='step'>
                    <Box className='step-title'>
                      <LocalShippingIcon fontSize="large" />{intl.formatMessage({id: 'how-it-works.we-step-2-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.we-step-2-content'})}
                    </Box>
                  </Box>
                  <Box className='step'>
                    <Box className='step-title'>
                      <SupportAgentIcon fontSize="large" />{intl.formatMessage({id: 'how-it-works.we-step-3-title'})}
                    </Box>
                    <Box className='step-content'>
                      {intl.formatMessage({id: 'how-it-works.we-step-3-content'})}
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box className='section benefits'>
        <Box className='title'>
          <h1>{intl.formatMessage({id: 'benefits.title'})}</h1>
        </Box>
        <Box className='three-panels'>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <Box className='content'>
                <Box className='icon'>
                  <FreeBreakfastIcon fontSize="large" />
                </Box>
                <Box className='panel-content'>
                  <Box className='panel-title'>
                    {intl.formatMessage({id: 'benefits.benefit-1-title'})}
                  </Box>
                  <Box className='panel-text'>
                    {intl.formatMessage({id: 'benefits.benefit-1-content'})}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className='content'>
                <Box className='icon'>
                  <BedtimeIcon fontSize="large" />
                </Box>
                <Box className='panel-content'>
                  <Box className='panel-title'>
                    {intl.formatMessage({id: 'benefits.benefit-2-title'})}
                  </Box>
                  <Box className='panel-text'>
                    {intl.formatMessage({id: 'benefits.benefit-2-content'})}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Box className='content'>
                <Box className='icon'>
                  <SavingsIcon fontSize="large" />
                </Box>
                <Box className='panel-content'>
                  <Box className='panel-title'>
                    {intl.formatMessage({id: 'benefits.benefit-3-title'})}
                  </Box>
                  <Box className='panel-text'>
                    {intl.formatMessage({id: 'benefits.benefit-3-content'})}
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        {!userBoutique && !userProduction && <Box className='cta-container'>
          {/*<div className='cta' onClick={clickBoutique}>*/}
          {/*  {intl.formatMessage({id: 'home.cta3'})}*/}
          {/*</div>*/}

          <Button color='success' variant='contained' key='start'
                  sx={{display: 'flex', fontSize: 'var(--fs-lg)'}} onClick={clickBoutique}>
            <RocketLaunchIcon sx={{mr: 1}}/>
            <FormattedMessage id="home.cta3"/>
          </Button>

        </Box>}
      </Box>

      <Footer intl={intl}/>
    </div>
  </>
  )
}

export default HomePage;