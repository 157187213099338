import axios from "./axios";
import {CLIENT_ID, CLIENT_SECRET} from "./global";
import {IProfileUpdate, ISignupPayload} from "../features/sessions/sessionSlice";

const LOGIN_URL = "/oauth/token";
const MAGIC_LINK_URL = "/boudists/magic_link";
const PASSWORD_LINK_URL = "/boudists/password_link";
const PASSWORD_CONFIRM_URL = "/boudists/password_confirm";
const SIGNUP_URL = "/boudists";
const UPDATE_PROFILE_URL = "/boudists";
const NEW_CONFIRM_LINK_URL = "/boudists/new_confirmation";
const CONFIRM_URL = "/boudists/confirm";
const CANCEL_EMAIL_CHANGE_URL = "/boudists/cancel_email_change";
const LOGOUT_URL = "/oauth/revoke";
const CURRENT_USER_URL = "/boudists/me";
const REMOVE_HELP_TIP_URL = "/boudists/remove_help_tip";

export const getRefreshToken = () => localStorage.getItem("refreshToken");
export const removeRefreshToken = () => localStorage.removeItem("refreshToken");

export const getAccessToken = () => localStorage.getItem("accessToken");
export const removeAccessToken = () => localStorage.removeItem("accessToken");

export const storeSessionTokens = (access: string, refresh: string) => {
  localStorage.setItem("accessToken", access)
  localStorage.setItem("refreshToken", refresh)
  if (access === undefined || access === 'undefined') {
    removeAccessToken()
  }
  if (refresh === undefined || refresh === 'undefined') {
    removeRefreshToken()
  }
}

export const removeSessionTokens = () => {
  localStorage.removeItem("accessToken")
  localStorage.removeItem("refreshToken")
  localStorage.removeItem("iprsnt");
}

export async function createUser(payload: ISignupPayload) {

  const data = {
    email: payload.email,
    password: payload.password,
    first_name: payload.firstName,
    last_name: payload.lastName,
    locale: payload.locale,
    color1: payload.color1,
    color2: payload.color2,
    product: payload.selection,
    boutique: payload.boutique,
    client_id: CLIENT_ID,
  };

  return axios
    .post(SIGNUP_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function loginWithEmailAndPassword(
  email: string,
  password: string
) {
  const data = {
    grant_type: "password",
    email: email,
    password: password,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGIN_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function loginWithToken(login_token: string) {
  const data = {
    grant_type: "password",
    magic_token: login_token,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGIN_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });

}

export async function requestMagicLink(
  email: string, locale: string
) {
  const data = {
    email: email,
    locale: locale,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(MAGIC_LINK_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function requestPasswordLink(
  email: string, locale: string | undefined
) {
  const data = {
    email: email,
    locale: locale,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(PASSWORD_LINK_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function updateUserProfile(profile: IProfileUpdate) {
  const data = {
    current_password: profile.currentPassword,
    email: profile.email,
    first_name: profile.firstName,
    last_name: profile.lastName,
    locale: profile.locale,
    color1: profile.color1,
    color2: profile.color2,
    password: profile.password,
    need_password: profile.needPassword,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(UPDATE_PROFILE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestNewConfirmation() {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(NEW_CONFIRM_LINK_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function confirmUserEmail(confirmation_token: string, demo: boolean) {
  const data = {
    confirmation_token: confirmation_token,
    demo: demo,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(CONFIRM_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}


export async function cancelEmailChangeRequest() {
  const data = {
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(CANCEL_EMAIL_CHANGE_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function confirmPasswordToken(password_token: string) {
  const data = {
    password_token: password_token,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(PASSWORD_CONFIRM_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function logoutUserOnServer() {
  const data = {
    token: getAccessToken(),
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  removeSessionTokens();

  return axios
    .post(LOGOUT_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestNewRefreshToken(refreshToken: string) {

  const data = {
    grant_type: "refresh_token",
    refresh_token: refreshToken,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .post(LOGIN_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function getCurrentUser() {
  return axios
    .get(CURRENT_USER_URL)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}

export async function requestRemoveHelpTip(component: string, anchor: string) {
  const data = {
    component: component,
    anchor: anchor,
    client_id: CLIENT_ID,
    client_secret: CLIENT_SECRET,
  };

  return axios
    .patch(REMOVE_HELP_TIP_URL, data)
    .then((response: any) => {
      return response.data;
    })
    .catch((error: any) => {
      return error.response.data;
    });
}
