import {ProductDetails} from "./features/productDetails/ProductDetails";
import React, {useContext, useEffect, useRef, useState} from "react";
import {useAppSelector} from "./app/hooks";
import {
  productShownSelector
} from "./features/productDetails/productDetailsSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "./app/store";
import {BrowserRouter as Router, Link, Route, Routes, useLocation, useNavigate, useParams} from 'react-router-dom';
import AppBar from "./features/appbar/AppBar";
import {
  closeConfirmDialog, closeFullScreen,
  closeSnackBar,
  confirmDialogState, fullScreenComponent, hideBackDrop, openSnackBar, showBackDrop,
  showBackDropState,
  snackBarState
} from "./features/global/globalSlice";
import PrivateRoute from "./features/routes/PrivateRoute";
import Logout from "./features/sessions/Logout";
import Profile from "./features/sessions/Profile";
import PublicOnlyRoute from "./features/routes/PublicOnlyRoute";
import Login from "./features/sessions/Login";
import Signup from "./features/sessions/Signup";
import {
  cancelEmailChange,
  speedySignupShowing
} from "./features/sessions/sessionSlice";
import SpeedySignup from "./features/sessions/SpeedySignup";
import {CatalogCategories} from "./app/catalog";
import {fetchProductsAsync, updateProductsProperty} from "./features/catalog/catalogSlice";
import {
  Alert,
  Button, CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  ThemeProvider
} from "@mui/material";
import Slide, { SlideProps } from '@mui/material/Slide';
import Catalog from "./features/catalog/Catalog";
import {Dashboard} from "./features/dashboard/Dashboard";
import DialogContentText from "@mui/material/DialogContentText";
import {CustomButton} from "./features/utils/CustomButton";
import {FormattedMessage, useIntl} from "react-intl";
import {getAccessToken} from "./app/sessionAPI";
import Backdrop from "@mui/material/Backdrop";
import WorkshopBoxDetails from "./features/admin/production/WorkshopBoxDetails";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {CatalogAdmin} from "./features/admin/catalog/CatalogAdmin";
import {ProductionAdmin} from "./features/admin/production/ProductionAdmin";
import {ReadQrCode} from "./features/admin/production/ScanQRCode";
import ProductionOrder from "./features/admin/production/ProductionOrder";
import './features/admin/production/production.scss';
import Box from "@mui/material/Box";
import {LogsAdmin} from "./features/admin/logs/LogsAdmin";
import StockItemDetails from "./features/admin/production/StockItemDetails";
import {
  getlogos,
  logosLoadedSelector,
  removeLogo, setLogos
} from "./features/logos/logosSlice";
import {eraseSelection, setSelectedProducts} from "./features/selection/selectionSlice";
import {
  applyBoutiqueChanges,
  deleteBoutique,
  deleteDraftBoutique,
  deployBoutique, getBoutiques, openDemoBoutique, storeBoutiqueInfo, updateBoutiques
} from "./features/dashboard/boutiqueSlice";
import {DemoBoutique} from "./features/dashboard/DemoBoutique";
import {cable, UserSubscriptions} from "./app/cable";
import {Tooltip} from "react-tooltip";
import helpToolTip from "./app/helpToolTip";
import {IProductPropertyUpdate} from "./app/productAPI";
import HomePage from "./features/website/HomePage";
import TermsOfService from "./features/website/TermsOfService";
import TermsOfSale from "./features/website/TermsOfSale";
import LegalNotices from "./features/website/LegalNotices";
import HelpPage from "./features/website/HelpPage";
import {I18nContext} from "./features/locale/LocaleWrapper";
import { Helmet } from "react-helmet-async";


const defaultTheme = createTheme({
  typography: {
    fontFamily: ['Barlow Condensed', 'sans-serif'].join(','), // roboto condensed
    fontSize: 16,
    button: {
      textTransform: "none"
    }
  },
});

const adminTheme = createTheme({
  typography: {
    fontFamily: ['Roboto Condensed', 'sans-serif'].join(','), // roboto condensed
    fontSize: 16,
    button: {
      textTransform: "none"
    }
  },
});

const App = ()=> {
  const intl = useIntl();
  const context = useContext(I18nContext);
  const dispatch = useDispatch<AppDispatch>();
  const displayBackDrop = useAppSelector(showBackDropState);
  const productShown = useAppSelector(productShownSelector);
  const showingSpeedySignup = useAppSelector(speedySignupShowing);
  const {snackBarOpened, snackBarSeverity, snackBarMessage, noAutoClose} = useAppSelector(snackBarState);
  const {dialogOpened, dialogTitle, dialogMessage, dialogMessageHTML, dialogConfirm, dialogConfirmColor, dialogAction, dialogActionMethod, dialogActionParam} = useAppSelector(confirmDialogState);
  const fullScreen = useAppSelector(fullScreenComponent);
  // const tooltip = useRef<TooltipRefProps>(null)

  useEffect(() => {
    CatalogCategories.forEach(category => {
      dispatch(fetchProductsAsync(category.code));
    });
  }, [dispatch]);

  const accessToken = getAccessToken();
  const currentUser = useSelector((state : RootState) => state.session.currentUser);
  const loggedUserNotConfirmed = currentUser !== undefined && currentUser.id !== undefined &&
                      (!!currentUser.unconfirmed_email || !currentUser.confirmedAt);
  const boutiquesLoaded = useAppSelector((state: RootState) => state.boutique.loaded);
  const logosLoaded = useSelector(logosLoadedSelector);

  useEffect(() => {
    if (accessToken && accessToken !== 'undefined' && !logosLoaded) {
      // console.log("Dashboard useEffect dispatching getlogos because accessToken = ", accessToken)
      dispatch(getlogos(''))
    }

    if (accessToken && accessToken !== 'undefined' && !boutiquesLoaded) {
      // console.log("Dashboard useEffect dispatching getBoutiques because accessToken = ", accessToken)
      dispatch(getBoutiques());
    }

  }, [dispatch, accessToken]);

  const subscribeToLogos = (current_user_id: number) =>
    cable.subscriptions.create(
      { channel: "LogosChannel" },
      { received: (payload: any) => {
          if (payload.user_id === current_user_id) {
            // console.log("%cDashboard received logo broadcast with accessToken = " + payload.accessToken, "color: blue");
            if (payload.originAccessToken === accessToken) {
              // console.log("%cDashboard received logo broadcast with own accessToken, not doing anything ...", "color: red");
            } else {
              // console.log("%cDashboard received logo broadcast not with own accessToken, applying logos ...", "color: red");
              dispatch(setLogos(payload.logos));
            }
          }
        }
      }
    );


  const subscribeToBoutiques = (current_user_id: number) =>
    // console.log("%cDashboard subscribing to BoutiquesChannel ...", "color: blue");
    cable.subscriptions.create(
      { channel: "BoutiquesChannel" },
      { received: (payload:any) => {
          // console.log("%cDashboard received boutiques broadcast with payload = " + JSON.stringify(payload), "color: blue");
          if (payload.user_id === current_user_id) {
            // console.log("%cDashboard received boutiques broadcast with accessToken = " + payload.accessToken, "color: blue");
            if (payload.originAccessToken === accessToken) {
              // console.log("%cDashboard received boutiques broadcast with own accessToken, not doing anything ...", "color: red");
            } else {
              // console.log("%cDashboard received boutiques broadcast with null accessToken, so updating the boutique ...", "color: green");
              dispatch(updateBoutiques(payload.boutiques));
            }
          }
        }
      }
    );


  ////////////////////////////////////////////////////////////////////////////////////
  // Receive selection broadcasts in case the user is logged in on multiple devices
  const subscribeToSelection = (current_user_id: number) =>
    cable.subscriptions.create(
        { channel: "SelectionChannel" },
        { received: (payload:any) => {
            if (payload.user_id === current_user_id) {
              // console.log("%cProductSelection received selection broadcast with accessToken = " + payload.originAccessToken, "color: blue");
              if (payload.originAccessToken === accessToken) {
                // console.log("%cProductSelection received selection broadcast with own accessToken, not doing anything ...", "color: red");
              } else {
                // console.log("%cDashboard received selection broadcast with null accessToken, so updating the selected products ...", "color: green");
                dispatch(setSelectedProducts(payload));
              }
            }
          }
        }
    );

  useEffect(() => {

    // Subscribe to all channels when the user is logged in
    if (accessToken && currentUser && currentUser?.id !== undefined) {
      if (!UserSubscriptions.selection) {
        UserSubscriptions.selection = subscribeToSelection(currentUser.id);
      }
      if (!UserSubscriptions.logos) {
        UserSubscriptions.logos = subscribeToLogos(currentUser.id);
      }
      if (!UserSubscriptions.boutiques) {
        UserSubscriptions.boutiques = subscribeToBoutiques(currentUser.id);
      }
    }

    // Unsubscribe from all channels when the user logs out
    if (currentUser && currentUser.id === undefined) {
      if (UserSubscriptions.selection) {
        UserSubscriptions.selection.unsubscribe();
        UserSubscriptions.selection = null;
      }
      if (UserSubscriptions.logos) {
        UserSubscriptions.logos.unsubscribe();
        UserSubscriptions.logos = null;
      }
      if (UserSubscriptions.boutiques) {
        UserSubscriptions.boutiques.unsubscribe();
        UserSubscriptions.boutiques = null;
      }
    }
  }, [currentUser]);

  const closeDeleteDialogAndAct = async (do_action: boolean) => {
    let response: any;

    if (do_action && dialogAction) {
      dialogAction();
    }

    if (do_action && dialogActionMethod) {
      switch(dialogActionMethod) {

        case 'handleCancelEmailChange':
          response = await dispatch(cancelEmailChange()) as any;

          if (response.type === 'session/cancelEmailChange/fulfilled') {
            // setOtherErrors([]);
            dispatch(openSnackBar({
              severity: "success",
              message: intl.formatMessage({id: "session.cancel-new-email-done"})}))
          }
          break;

        case 'applyBoutiqueChanges':
          if (typeof dialogActionParam === "number") {
            dispatch(applyBoutiqueChanges(dialogActionParam));
          }
          break;

        case 'deleteBoutiqueChanges':
          const {boutiqueId, draftOf} = dialogActionParam as {boutiqueId: number, draftOf: number};
          if (boutiqueId && boutiqueId > 0) {
            response = await dispatch(deleteBoutique(boutiqueId)) as any;
            if (response.type === 'boutique/deleteBoutique/fulfilled') {
              dispatch(openSnackBar({
                severity: "success",
                message: intl.formatMessage({id: "boutique.draft-deleted"})}));
            }
          } else {
            dispatch(deleteDraftBoutique({id: boutiqueId, draftOf: draftOf}));
            dispatch(openSnackBar({
              severity: "success",
              message: intl.formatMessage({id: "boutique.draft-deleted"})}))
          }
          break;

        case 'deployBoutique':
          if (typeof dialogActionParam === "number") {
            dispatch(deployBoutique(dialogActionParam));
          }
          break;

        case 'removeLogo':
          // check if dialogActionParam is a number
            if (typeof dialogActionParam === "number") {
              response = await dispatch(removeLogo(dialogActionParam)) as any;
              if (response.type === 'logos/removeLogo/fulfilled') {
                openSnackBar({severity: 'success', message: intl.formatMessage({ id: "logos.deleted"})})
              } else {
                openSnackBar({severity: 'error', message: <FormattedMessage id="logos.not-deleted" />})
              }
            }
          break;

        case 'emptySelection':

          dispatch(showBackDrop())

          response = await dispatch(eraseSelection()) as any;

          if (response.type === 'selection/eraseSelection/fulfilled') {
            // if the selection was not totally emptied, warn the user
            if (response.payload.selection.length > 0) {
              dispatch(openSnackBar({severity: "warning", message: intl.formatMessage({ id: "selection.not-emptied"})}));
            } else {
              dispatch(openSnackBar({severity: "success", message: intl.formatMessage({ id: "selection.emptied"})}));
            }
          }

          dispatch(hideBackDrop())
          break;

        case 'definePersoColor':

          const param = dialogActionParam as string;
          // param will be in the form `${colorValue}-${colorIndex}`
          const colorValue = param.split('-')[0];
          const colorIndex = param.split('-')[1];
          const colorField = colorIndex == '1' ? 'color_dark_bg' : 'color_light_bg';

          dispatch(storeBoutiqueInfo({field: colorField, value: colorValue}))

          break;

        case 'updateProductsProperty':
          dispatch(updateProductsProperty(dialogActionParam as IProductPropertyUpdate));
          break;

        default:
          console.error("Unknown action method " + dialogActionMethod);
      }
    }

    dispatch(closeConfirmDialog());
  };

  const confirmDialog = () => {
    const s = 1;
    return (
      <div>
        <Dialog
          open={dialogOpened}
          onClose={() => closeDeleteDialogAndAct(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {dialogTitle}
          </DialogTitle>
          <DialogContent>
            {dialogMessageHTML &&
                <DialogContentText id="alert-dialog-description" dangerouslySetInnerHTML={{__html: dialogMessageHTML}} />
              ||
		            <DialogContentText id="alert-dialog-description">
                  {dialogMessage}
		            </DialogContentText>
            }
          </DialogContent>
          <DialogActions>
            <CustomButton onClick={() => closeDeleteDialogAndAct(false)}>
              <FormattedMessage id="global.cancel" />
            </CustomButton>
            <Button variant="contained" color={dialogConfirmColor || 'error'}
                    onClick={() => closeDeleteDialogAndAct(true)} autoFocus>
              {dialogConfirm}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  type TransitionProps = Omit<SlideProps, 'direction'>;

  function TransitionUp(props: TransitionProps) {
    return <Slide {...props} direction="up" timeout={600} />;
  }

  const fullScreenContent = () => {
    if (!fullScreen) return null;
    switch (fullScreen.name) {
      case 'ReadQrCode':
        console.log("Rendering ReadQrCode with targetType=" + fullScreen.targetType + " targetId=" + fullScreen.targetId + " objectId=" + fullScreen.displayedId);
        return <ReadQrCode targetType={fullScreen.targetType} targetId={fullScreen.targetId} objectId={fullScreen.displayedId} />;

      case 'ProductionOrder':
        return <ProductionOrder orderNumber={fullScreen.displayedId} fullScreen={true} viewFrom={fullScreen.param} />

      case 'ProductionItem':
        return <ProductionOrder prodItemId={fullScreen.displayedId} fullScreen={true} viewFrom={fullScreen.param} />

      case 'WorkshopBoxDetails':
        return <WorkshopBoxDetails workshopBoxId={fullScreen.displayedId}/>;

      case 'StockItemDetails':
        return <StockItemDetails stockItemId={fullScreen.displayedId} />;

      default:
        return (
          <div className='full-screen-content'>Unknown full screen component {fullScreen.name}</div>
        );
    }
  }

  const fullScreenWindow = () => {
    if (!fullScreen) return null;

    return(
      <Box className='full-screen'>
        <Box className='full-screen-content'>
          {fullScreenContent()}
        </Box>
        <IconButton size='large' className='close-full-window' aria-label="close"
                    onClick={() => dispatch(closeFullScreen())}>
          <CloseIcon fontSize='large' />
        </IconButton>
      </Box>
    )
  }

  // console.log("%cRendering App with productShown " + productShown, "color: blue");

  return (<>
    <Helmet>
      <html lang={context.locale} />
    </Helmet>
    <ThemeProvider theme={defaultTheme}>
      <Router>
        {fullScreen && fullScreenWindow() || <>
          <header>
            <AppBar />
          </header>
          <main>
            {loggedUserNotConfirmed && <Link to="/profile" className='no-decoration'>
              <Alert severity="error" className='account-not-confirmed' >
                <FormattedMessage id={`session.${!currentUser.unconfirmed_email ? 'not-confirmed' : 'email-not-changed'}`}
                                  values={{b: chunks => <b>{chunks}</b>,
                                    email: currentUser.unconfirmed_email || currentUser.email }}
                />
              </Alert>
            </Link>}

            <Routes>
	            <Route path="/" element={ <HomePage />} />
	            <Route path="/help" element={ <HelpPage />} />
              <Route path="/catalog/:category?/:slug?" element={ <Catalog />} />
	            <Route path="/terms-of-service" element={ <TermsOfService />} />
	            <Route path="/terms-of-sale" element={ <TermsOfSale />} />
	            <Route path="/legal-notices" element={ <LegalNotices />} />

              <Route path="/signin/:magic_token" element={ <Login />} />
              <Route path="/confirm/:confirmation_token" element={ <Profile />} />
              <Route path="/demo/:demo_token" element={ <Catalog />} />
              <Route path="/password/:password_token" element={ <Profile />} />
              <Route path="/profile" element={
                  <PrivateRoute>
                    <Profile />
                  </PrivateRoute>
                } />
              <Route path="/dashboard/:token" element={ accessToken && <Dashboard />} />
              <Route path="/dashboard" element={
                <PrivateRoute>
                  {accessToken && <Dashboard />}
                </PrivateRoute>
              } />
              <Route path="/admin/catalog" element={
                <PrivateRoute>
                  {accessToken && <CatalogAdmin />}
                </PrivateRoute>
              }/>
              <Route path="/admin/production/*" element={
                <ThemeProvider theme={adminTheme}>
                  <PrivateRoute>
                    {accessToken && <ProductionAdmin />}
                  </PrivateRoute>
                </ThemeProvider>
              }/>
              <Route path="/admin/logs" element={
                <PrivateRoute>
                  {accessToken && <LogsAdmin />}
                </PrivateRoute>
              }/>
              <Route path="/logout" element={
                <PrivateRoute>
                  {accessToken && <Logout/> }
                </PrivateRoute>
              } />
              <Route path="/login" element={
                <PublicOnlyRoute>
                  <Login />
                </PublicOnlyRoute>
              }/>
              <Route path="/signup" element={
                <PublicOnlyRoute>
                  <Signup />
                </PublicOnlyRoute>
              }/>
              <Route path="*" element={<HomePage />} />
            </Routes>
            {productShown && <ProductDetails key={`pshown-${productShown.id}`} product={productShown} />}
            {showingSpeedySignup && <SpeedySignup />}
          </main>
        </>}

        <Tooltip className='bouda-tip' classNameArrow='tip-arrow' opacity={0.97} offset={15}
                 ref={helpToolTip} clickable={true} imperativeModeOnly={true}
                 openEvents={{
                   dblclick: true,  // workaround to avoid closing https://github.com/ReactTooltip/react-tooltip/issues/1226
                 }} closeEvents={{}} globalCloseEvents={{}} />

        {snackBarOpened && <Snackbar open={true} onClose={() => dispatch(closeSnackBar())}
                                     autoHideDuration={noAutoClose ? null : 7000}
                                     anchorOrigin={{ vertical:'top', horizontal:'center'}}
                                     TransitionComponent={TransitionUp} >
          <Alert onClose={() => dispatch(closeSnackBar())} severity={snackBarSeverity ?? 'success'} sx={{ width: '100%' }}>
            {snackBarMessage}
          </Alert>
        </Snackbar>}
        {confirmDialog()}
        <DemoBoutique />
        <Backdrop sx={{ color: '#fff', zIndex: 1}} open={displayBackDrop} >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Router>
    </ThemeProvider>
  </>
  );
}

export default App;
