import React, {useState, useEffect } from 'react';
import InlineEdit from "../utils/InlineEdit";
import {
  ICollection,
  storeCollectionInfo,
  deleteCollection, ICollectionProduct, moveProducts, productAttachedLeft, productAttachedRight
} from "./boutiqueSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../app/store";
import {useDroppable} from '@dnd-kit/core';
import CollectionProductCard from "./CollectionProductCard";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import {ReactSortable} from "react-sortablejs";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useIntl} from "react-intl";
// @ts-ignore
import variables from "../../styles/_variables.scss";

export const MAX_PRODUCTS_PER_COLLECTION = 9;

export function CollectionCard (props: {
  collection: ICollection,
  editMode: boolean,
  errorShopNbProducts: boolean,
  maxProducts: number,
  first: boolean, last: boolean,
  move: (id: number, direction: string) => void
}) {

  const intl = useIntl();

  const {collection,  editMode, errorShopNbProducts, maxProducts, first, last, move} = props;
  const errorCollNbProducts = collection && collection.nbProducts && collection.nbProducts > MAX_PRODUCTS_PER_COLLECTION;
  const errorNbProducts = errorCollNbProducts || errorShopNbProducts;
  const products = collection.products;

  const collProductUniqueId = (colProduct: ICollectionProduct) =>
    `cpuid${collection.id}-${colProduct.productId || 'nw'}-${colProduct.product?.id}-${colProduct.version}`

  interface SortableProduct {
    id: string,
    sourceCollectionID: number,
    collectionProduct: ICollectionProduct
  }

  const productList = products
    .filter((col_p) => col_p.product)
    .map((col_p) => ({
      id: collProductUniqueId(col_p),
      sourceCollectionID: collection.id,
      collectionProduct: col_p,
      attachedRight: productAttachedRight(collection, col_p),
      attachedLeft: productAttachedLeft(collection, col_p)
    }));

  const [movingIndex, setMovingIndex] = useState(-1);


  const handleSortProducts = (newSortabableProducts: SortableProduct[]) => {

    // check new order before dispatching
    let newOrder = newSortabableProducts.map((sortableProduct) => sortableProduct.id).join('-');
    let oldOrder = productList.map((sortableProduct) => sortableProduct.id).join('-');

    if (newOrder !== oldOrder) {

      const movingSortableProduct = newSortabableProducts.find((sortableProduct) => sortableProduct.sourceCollectionID !== collection.id)

      if (movingSortableProduct !== undefined) {
        dispatch(moveProducts({
          collectionId: collection.id,
          oldCollectionId: movingSortableProduct.sourceCollectionID,
          movingCollProduct: movingSortableProduct.collectionProduct,
          collProducts: newSortabableProducts.map((sortableProduct) => sortableProduct.collectionProduct)
        }));
      } else {
        if (movingIndex !== -1 && newSortabableProducts.length === productList.length) {
          dispatch(moveProducts({
            collectionId: collection.id,
            oldCollectionId: collection.id,
            movingCollProduct: productList[movingIndex].collectionProduct,
            collProducts: newSortabableProducts.map((sortableProduct) => sortableProduct.collectionProduct)
          }));
        }
      }
    }
  }

  // Drop products inside collection
  const {isOver: isOverstyleProductCollection, setNodeRef: setNodeRefProductCollection} = useDroppable({
    id: 'collection-' + collection.id,
    data: {
      collection_id: collection.id,
      // accepts: ['logo','product']
      accepts: ['product']
    }
  });

  const styleProductCollection = {
    border: isOverstyleProductCollection ? '2px darkorange dashed'
      : `2px ${errorNbProducts ? variables.errorCollectionBg : editMode ? variables.editCollectionBg : variables.collectionBg} solid`,
  };

  const dispatch = useDispatch<AppDispatch>();

  const setInfos = (field: string, value:string) => {
    // console.log("setInfos " + field + " " + value)
    dispatch(storeCollectionInfo({id: collection.id, info: {field: field,value: value}}))
  }

  const changeSeparate = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(storeCollectionInfo({id: collection.id, info: {field: 'separate', value: event.target.checked}}))
  }

  const moveUpIconPosition = last ? '25px' : '50px';

  const maxCollProductsMessage = intl.formatMessage({id: "collection.max-products"}, {max: MAX_PRODUCTS_PER_COLLECTION})
  const maxShopProductsMessage = intl.formatMessage({id: "boutique.max-products"}, {max: maxProducts})

  const nbArticlesTag = <span className={`nb-articles ${errorNbProducts ? 'error' : ''}`}>
      ({collection.nbProducts}{errorCollNbProducts && <span className='error'> / {maxCollProductsMessage}</span>})
      {errorShopNbProducts && <span className='error'>({maxShopProductsMessage})</span>}
  </span>;

  // console.log("Render CollectionCard ", collection.id, collection.name)
  return(
    <div className={`collection ${editMode ? 'edit' : ''} ${errorCollNbProducts ? 'errorCollNbProducts' : ''}`}
         key={`col-${collection.id}`} id={`col-${collection.id}`}>
      <div ref={setNodeRefProductCollection} style={styleProductCollection}>
        {editMode && <>
			    <IconButton sx={{position: 'absolute', right: '-4px', top: '0px', color: '#808080'}} size='small'
			                onClick={() => dispatch(deleteCollection({collectionId: collection.id}))}>
				    <DeleteIcon/>
			    </IconButton>
          {!first && <IconButton sx={{position: 'absolute', right: moveUpIconPosition, top: '0px', color: '#808080'}}
			                           size='small' onClick={() => move(collection.id, 'up')}>
				    <ArrowUpwardIcon/>
			    </IconButton>}
          {!last && <IconButton sx={{position: 'absolute', right: '25px', top: '0px', color: '#808080'}}
			                          size='small' onClick={() => move(collection.id, 'down')}>
				    <ArrowDownwardIcon/>
			    </IconButton>}
		    </>}
        <div className='title'>
          <span id={`edit-collection-${first ? '0' : collection.id}`}>
            <InlineEdit key={`col-${collection.id}-name`} text={collection.name} editable={editMode}
                        onSetText={(value: string) => setInfos('name', value)}/>
          </span> {nbArticlesTag}
        </div>
        <ReactSortable group='products' disabled={!editMode}
                       className='products' animation={400} delay={1}
                       easing='cubic-bezier(0.65, 0, 0.35, 1)' dragoverBubble={true}
                       list={productList} setList={handleSortProducts} onChoose={
          (evt) => {
            // console.log("onEnd + " + evt.oldIndex);
            if (evt.oldIndex !== undefined) {
              setMovingIndex(evt.oldIndex);
            }
          }
        }>
          {productList.map((sortableProduct, idx) =>
            <CollectionProductCard key={sortableProduct.id} id={sortableProduct.id} index={idx} editMode={editMode}
                                   collectionId={sortableProduct.collectionProduct.collectionId}
                                   collectionProduct={sortableProduct.collectionProduct}
                                   attachedLeft={sortableProduct.attachedLeft}
                                   attachedRight={sortableProduct.attachedRight}
            />)
          }
        </ReactSortable>

        {editMode && (collection.hasSiblings || collection.separate) &&
				    <div className={`separate ${collection.separate ? 'active' : ''}`}>
              <span id={`separate-versions-${first ? '0' : collection.id}`}>
                <FormControlLabel sx={{marginRight: '5px'}} label={intl.formatMessage({id: "collection.separate"})}
                                  control={<Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='medium'
                                                     checked={collection.separate} onChange={changeSeparate}/>}
                />
              </span>
				    </div>
        }
      </div>
    </div>
  )
}
