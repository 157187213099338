import React, {useState} from 'react';
import {ILogoInfos, ILogoVersion} from "../logos/logosSlice";
import {IAddonInfos, ICollectionProduct, IImageAddon} from "./boutiqueSlice";
import {FormattedMessage, FormattedNumber} from "react-intl";
import {SelectAddonLogo} from "./SelectAddonLogo";
import {Checkbox, FormControlLabel} from "@mui/material";

export const LogoAddonConfig = (props: {
  collectionProduct: ICollectionProduct,
  imgAddon: IImageAddon,
  addonInfos: IAddonInfos,
  colorIndex: number,
  disabled: boolean,
  setAddonInfos: (colorIndex: number, addonInfos: IAddonInfos) => void,
  logos: ILogoInfos[],
  editMode: boolean,
  index: number
}) => {

  const {collectionProduct, imgAddon, addonInfos, colorIndex, disabled, setAddonInfos, logos, editMode, index} = props;

  const [logo, setLogo] = useState<ILogoInfos | undefined>(logos.find(l => l.id === addonInfos.logoId));
  const [logoVersion, setLogoVersion] = useState<ILogoVersion | undefined>(logo?.versions.find(v => v.id === addonInfos.logoVersionId));

  const handleSelectLogo = (logoId: number, logoVersionId: number) => {
    // console.log('At LogoAddonConfig level, handleSelectLogo ' + logoId + ' version ' +  logoVersionId);

    const newLogo = logos.find(l => l.id === logoId);

    setLogo(newLogo);
    setLogoVersion(logos.find(l => l.id === logoId)?.versions.find(v => v.id === logoVersionId));

    setAddonInfos(colorIndex, {
      addonId: imgAddon.id,
      name: imgAddon.name,
      logoId: logoId,
      logoVersionId: logoVersionId,
      zoom: addonInfos.zoom,
      optional: addonInfos.optional
    })
  }

  const handleOptional = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setPersoActivated(event.target.checked);

    setAddonInfos(colorIndex, {
      addonId: imgAddon.id,
      name: imgAddon.name,
      logoId: addonInfos.logoId,
      logoVersionId: addonInfos.logoVersionId,
      zoom: addonInfos.zoom,
      optional: event.target.checked
    })
  }

  // console.log('Rendering image addon ' + imgAddon.name + ' with logo ' + addonInfos.logoId + ' version ' + addonInfos.logoVersionId);

  if (colorIndex === 0 || logoVersion) {
    return (
      <div id={index === 0 ? 'assign-logo' : `assgnlg${index}`} className={`logo-addon-config ${disabled ? 'disabled' : ''}  ${logoVersion ? 'selected' : ''}`}>
        <div className="addon-name">
          <div className='name'>
            <FormattedMessage id={`addon.${imgAddon.name}`}/>
          </div>
          {colorIndex === 0 &&
            <div className={`choose-logo optional ${addonInfos.optional ? 'checked' : ''}`}>
              <FormControlLabel label={<FormattedMessage id='addon.optional-logo' />}
                                labelPlacement="start"
                                control={
                                  <Checkbox sx={{'&.MuiCheckbox-root': {color: '#808080'}}} size='small'
                                            disabled={colorIndex > 0 || disabled || !editMode}
                                            checked={addonInfos.optional}
                                            onChange={handleOptional}/>
                                }/>
            </div>
          }
        </div>
        <div className='addon-logo-price'>
          <div className='select-addon-logo'>
            <SelectAddonLogo collectionProduct={collectionProduct} logos={logos} imageAddon={imgAddon}
                             addonInfo={addonInfos} selectLogoVersion={handleSelectLogo}
                             noneAllowed={colorIndex === 0} editMode={editMode} />
          </div>
          <div className={`addon-price ${addonInfos.optional ? 'optional' : ''}`}>
            {logoVersion &&
					      <FormattedNumber value={logoVersion.priceCents / 100} style="currency" currency={'EUR'}
                                 minimumFractionDigits={2} maximumFractionDigits={2}
              />}
          </div>
        </div>
      </div>
    )
  } else {
    return null;
  }
}
